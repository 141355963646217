import React from "react";

import BorderColorIcon from '@mui/icons-material/BorderColor';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";

function AdminsTable(props) {

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    return (
        <div>

            <TableContainer sx={{ height: "100%" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Email</TableCell>
                            <TableCell align="center">Projects</TableCell>
                            <TableCell align="center">Tasks</TableCell>
                            <TableCell align="center">Quality Assurance</TableCell>
                            <TableCell align="center">Annotators</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.admins.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                return (
                                    <TableRow tabIndex={-1} hover role="checkbox" >
                                        <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>{index + 1}</TableCell>
                                        <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>{row.name}</TableCell>
                                        <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>{row.email}</TableCell>
                                        <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>{row.project_numbers}</TableCell>
                                        <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>{row.task_numbers}</TableCell>
                                        <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>{row.quality_assurance_numbers}</TableCell>
                                        <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>{row.annotator_numbers}</TableCell>
                                        <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>
                                            <IconButton
                                                onClick={() => {
                                                    props.setSelectedAdmin(row);
                                                    props.setOpenDialog(true);
                                                }}
                                            >
                                                <BorderColorIcon />
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                  
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100, 500]}
                    component="div"
                    count={props.admins.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </div>
    )
}

export default AdminsTable;