import { Grid } from "@mui/material";
import React, { Component, Fragment } from "react";
import Annotation from "react-image-annotation";
import toast, { Toaster } from "react-hot-toast";
import user from "../api/user";
import AnnotationDash from "./AnnotationDash";
import qualityAssurance from "../api/qualityAssurance";
import { useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import { annotatorFeatures } from "../Data/usersFeatures";
import { Navigate } from "react-router-dom"
const Box = ({ children, geometry, style }) => (
  <div
    style={{
      ...style,
      position: "absolute",
      left: `${geometry.x}%`,
      top: `${geometry.y}%`,
      height: `${geometry.height}%`,
      width: `${geometry.width}%`,
    }}
  >
    {children}
  </div>
);

export default class ImageAnnotation extends Component {
  state = {
    annotations: [],
    selectedAnnotation: [],
    annotation: {},
    disabledBtn: true,
    currentClassification: [],
    classifications: [],
    taskInfo: [],
    disableAnnotation: true,
    imageUrl: "",
    redirect: false,
    currentUser: JSON.parse(localStorage.getItem("user"))

  };

  renderHighlight({ annotation, active }) {
    const { geometry } = annotation;

    if (!geometry) return null;

    return (
      <Box
        key={annotation.data.id}
        geometry={geometry}
        style={{
          border: "solid 3px red",

          boxShadow: active && "0 0 20px 20px rgba(255, 255, 255, 0.3) inset",
        }}
      ></Box>
    );
  }
  handelDelateAnnotation = (annotation) => {
    const items = this.state.annotations.filter((item) => {
      return item.data.id !== annotation.data.id;
    });

    this.setState({ annotations: items });
  };

  handleClassificationChange = async (e) => {
    this.setState({ currentClassification: e.target.value });
  };
  handelReset = async () => {
    if (this.state.taskInfo.status === 0 || this.state.taskInfo.status === 3) {
      this.setState({ annotations: [] });
    } else {
      const data = {
        task_id: this.state.taskInfo.id,
      };

      await qualityAssurance
        .rejectTask(data.task_id)
        .then((response) => {

          toast.success("Task rejected successfully", {
            style: { padding: "15px" },
          });
          setTimeout(() => {
            this.setState({ redirect: true });
          }, 500);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location = "/login"
          }

          toast.error("Error Please Try again", {
            style: { padding: "15px" },
          });
        });
    }
  };

  handelSubmit = async () => {
    // here we call to the backend to send the new take and return to the home page

    if (this.state.taskInfo.status === 0 || this.state.taskInfo.status === 3) {
      let data = {
        taskID: this.state.taskInfo.id,
        annotationData: JSON.stringify(this.state.annotations),
        annotatorType: JSON.parse(localStorage.getItem("user")).type,
        annotatorId: JSON.parse(localStorage.getItem("user")).id,
        owner_id: this.state.taskInfo.project_ownerid,
      };

      user
        .createAnnotation(data)
        .then((response) => {
          toast.success("Annotation submitted successfully", {
            style: { padding: "15px" },
          });
          setTimeout(() => {
            this.setState({ redirect: true });
          }, 500);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.clear();
            window.location = "/login"
          }
          toast.error("Error! please try again", {
            style: { padding: "15px" },
          });
        });
    } else {
      const data = {
        task_id: this.state.taskInfo.id,
        quality_assurance_id: JSON.parse(localStorage.getItem("user")).id,
      };
      await qualityAssurance
        .approveTask(data)
        .then((response) => {
          toast.success("the task approved successfully", {
            style: { padding: "15px" },
          });
          setTimeout(() => {
            this.setState({ redirect: true });
          }, 500);
          this.setState({ disabledBtn: true });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.clear();
            window.location = "/login"
          }

          toast.error("Error, please try again", {
            style: { padding: "15px" },
          });
        });
    }
  };

  onChange = (annotation) => {
    this.setState({ annotation });
  };

  deleteAnnotation = (annotation) => {
    this.state.annotations.pop();
    console.log(this.state.annotations);
    this.setState({ annotation: this.state.annotations });
  };

  createNewAnnotation = (annotation) => {
    const { geometry, data } = annotation;

    this.setState({
      annotation: {},
      annotations: this.state.annotations.concat({
        geometry,
        data: {
          text: this.state.currentClassification,
          id: Math.random(),
          color: this.state.currentClassification.color,
        },
      }),
    });
  };
  async componentDidMount() {
    // get the task from the database

    const taskID = window.location.href.split("/")[6];

    await user
      .getTask(taskID)
      .then((response) => {

      
        
        this.setState({ imageUrl: response.data.imageUrl });
        const data = response.data.data;

        const projectClassifications = JSON.parse(data.classifications);

        const annotations =
          data.status === 0 ? [] : JSON.parse(data.annotation_data);

        let classifications = [];

        projectClassifications.map((classification) => {
          const newClassification = {
            id: classification.id,
            title: classification.title,
            value: classification.title,
            color: classification.color,
          };
          classifications.push(newClassification);
        });
        
        this.setState({ classifications: classifications });



        this.setState({ annotations: annotations });

        this.setState({ taskInfo: data });

        this.setState({
          currentClassification: projectClassifications[0].title,
        });

        if (data.status === 0 || data.status === 3) {
          this.setState({ disableAnnotation: false });
        }
      })
      .catch((error) => {

        if (error.code === "ERR_NETWORK") {

          toast.error("Error! please try again", {
            style: { padding: "15px" },
          });

        } else if(error.code === "ERR_BAD_REQUEST"){

          if (error.response.status === 401) {
            localStorage.clear();
            window.location = "/login"
          } else {  
          toast.error("Error! please try again", {
            style: { padding: "15px" },
          });
        }
        }
      });
  }
  render() {
    return (
      <div>
        <Toaster position="bottom-left" reverseOrder={false} />
        <SideBar list={[]} currentDestination="Task" />

        {
          this.state.currentUser.type == 1 ? (
            this.state.redirect && <Navigate to='/admin/task' replace={true} />
          ) : (
            <>
              {this.state.currentUser.type == 2 ? (
                this.state.redirect && <Navigate to='/quality-assurance' replace={true} />
              ) : (
                this.state.redirect && <Navigate to='/annotator' replace={true} />
              )}

            </>
          )

        }
        <div className="md:p-1 sm:ml-64">
          <div className="p-1 md:mt-1">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item md={9}>
                <Annotation
                  src={this.state.imageUrl}
                  alt="image"
                  annotations={this.state.annotations}
                  type={this.state.type}
                  value={this.state.annotation}
                  onChange={this.onChange}
                  onSubmit={this.createNewAnnotation}
                  renderHighlight={this.renderHighlight}
                  renderEditor={(annotation) => {
                    annotation.onSubmit(annotation);
                    return;
                  }}
                  disableAnnotation={this.state.disableAnnotation}
                />
              </Grid>
              <Grid item md={3}>
                <AnnotationDash
                  taskName="Annotate Image"
                  taskDescription="Annotate the image based on the markdown Classification."
                  classification={this.state.currentClassification}
                  classifications={this.state.classifications}
                  // disabledBtn={this.state.disabled}
                  handelReset={this.handelReset}
                  handleClassificationChange={this.handleClassificationChange}
                  handelSubmit={this.handelSubmit}
                  taskAnnotation="Image Annotation"
                  annotations={this.state.annotations}
                  handelDelateAnnotation={this.handelDelateAnnotation}
                  taskStatus={this.state.taskInfo.status}
                  userType={JSON.parse(localStorage.getItem("user")).type}
                  task={this.state.taskInfo}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}
