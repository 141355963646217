import React, { useEffect, useState } from "react";
import user from "../../api/user";
import SideBar from "../../Components/SideBar";
import TasksTable from "../../Components/TasksTable";
import { annotatorFeatures } from "../../Data/usersFeatures";
import toast, { Toaster } from "react-hot-toast";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";

function AnnotatorTasks(props) {
  const [tasks, setTasks] = useState([]);

  const [isLoadingData,setIsLoadingData] = useState(true);

  useEffect(() => {
    let currentUser = JSON.parse(localStorage.getItem("user"));

    const user_id = currentUser.id;

    user
      .getUserTasks(user_id)
      .then((tasks) => {
        setTasks(tasks.data.tasks);
        setIsLoadingData(false);
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          toast.error("Error! please try again", {
            style: { padding: "15px" },
          });

        } else if (error.code === "ERR_NETWORK") { 
          if (error.response.status === 401) {
            localStorage.clear();
            window.location = "/login"
          } else {
            toast.error("Error! please try again", {
              style: { padding: "15px" },
            });
          }
        }
      });
  });
  return (
    <div className="App" style={{ minHeight: "100vh" }}>
      <Toaster position="bottom-left" reverseOrder={false} />
      <SideBar currentDestination="My Tasks" list={annotatorFeatures} />
      <div className="md:p-4 sm:ml-64">
        <div className="p-2 md:mt-3">
          <h4>Welcome, {JSON.parse(localStorage.getItem("user"))?.name} </h4>
          <h4>My Tasks </h4>

          {isLoadingData ? (
             <Box sx={{ display: 'flex', justifyContent: "center",height:"80vh" ,width:"100%" ,alignItems:"center"}}>
             <CircularProgress style={{ color: "#212e79" }} size="90px" />
           </Box>):(
            <>
             <TasksTable data={tasks} type={1} />
            </>
           )}
         
        </div>
      </div>
    </div>
  );
}
export default AnnotatorTasks;
