
import { FormControl, DialogContent, InputLabel, MenuItem, Select, TextField, Button, DialogActions } from '@mui/material';
import React, { useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import { LoadingButton } from "@mui/lab";
import support from '../api/support';


function AddTicket(props) {
    const [ticketName, setTicketName] = useState("");
    const [ticketDescription, setTicketDescription] = useState("");
    const [loading, setLoading] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(true);
    const [ticketType, setTicketType] = useState(0);


    const handleClose = () => {
        props.setOpenDialog(false);
    }

    const createTicket = async () => {

        let data = {
            title: ticketName,
            description: ticketDescription,
            type: ticketType
        };
        await support.createTicket(data).then((res) => {
            console.log(res.data.data);
            props.setOpenDialog(false);
            props.setTickets(tickets => [...tickets, res.data.data]);
            props.toast.success("Ticket Created Successfully", {
                style: { padding: "15px" },
            });
        })
            .catch((error) => {
                props.toast.error("Error! please try again", {
                    style: { padding: "15px" },
                });

            })
    }
    return (
        <div>
            <div className="p-4 border-b rounded-t">
                <p className="text-4xl font-thin text-gray-900 ">Add New Ticket</p>
                <p className="text-justify text-gray-500 ">
                    Please fill in all the fields..
                </p>
            </div>

            <DialogContent>

                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Ticket Title"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={ticketName}
                    onChange={(e) => {

                        if (e.target.value === "" || ticketDescription === "") {
                            setDisabledBtn(true);
                        } else {
                            setDisabledBtn(false);
                        }
                        setTicketName(e.target.value)
                    }}
                />

                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Ticket Description"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={ticketDescription}
                    onChange={(e) => {

                        if (e.target.value === "" || ticketName === "") {
                            setDisabledBtn(true);
                        } else {
                            setDisabledBtn(false);
                        }
                        setTicketDescription(e.target.value)
                    }}
                />

                <FormControl sx={{ width: "100%", marginTop: "10px" }}>

                    <InputLabel >Ticket Type</InputLabel>
                    <Select
                        value={ticketType}
                        onChange={(e) => setTicketType(e.target.value)}
                        input={<OutlinedInput label="Ticket Type" />}
                    >
                        <MenuItem value="0" >
                            Suggest
                        </MenuItem>
                        <MenuItem value="1">
                            Issue
                        </MenuItem>
                    </Select>
                </FormControl>

            </DialogContent >

            <DialogActions>
                <Button
                    style={{ margin: "10px" }}
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                >
                    Cancel
                </Button>
                <LoadingButton
                    style={{ margin: "10px", backgroundColor: disabledBtn ? "gray" : "#212e79", color: "white" }}
                    size="medium"
                    loading={loading}
                    variant="contained"
                    onClick={createTicket}
                    disabled={disabledBtn}
                >
                    Create
                </LoadingButton>
            </DialogActions>
        </div>
    )
}

export default AddTicket;