import { Dialog, Grid, Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddTicket from "../Components/AddTicket";
import SideBar from "../Components/SideBar";
import toast, { Toaster } from "react-hot-toast";
import SupportTable from "../Components/SupportTable";
import support from "../api/support";


function Support() {

    const [openDialog, setOpenDialog] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(true);
    let currentUser = JSON.parse(localStorage.getItem("user"));
    useEffect(() => {
        const getData = async () => {

           
            if (currentUser?.type == 0) {
                support.getAllTickets()
                    .then(tickets => {
                        setIsLoadingData(false);
                        setTickets(tickets.data.tickets);

                    }).catch(error => {
                        if (error.code === "ERR_NETWORK") {
                            toast.error("Error! please try again", {
                                style: { padding: "15px" },
                            });
                        } else if (error.response.status === 401) {
                            localStorage.clear();
                            window.location = "/login"
                        } else {
                            toast.error("Error! please try again", {
                                style: { padding: "15px" },
                            });
                        }
                    });
            } else {
                await support.getMyTicket()
                    .then((response) => {
                        setTickets(response.data.tickets);
                    }).catch((error) => {
                        if (error.code === "ERR_NETWORK") {

                            toast.error("Error! please try again", {
                                style: { padding: "15px" },
                            });

                        } else if (error.response.status === 401) {
                            localStorage.clear();
                            window.location = "/login"
                        } else {
                            toast.error("Error! please try again", {
                                style: { padding: "15px" },
                            });
                        }
                    });
            }
        }
        getData()
    }, [])
    return (

        <div className="App" style={{ minHeight: "100vh" }}>
            <SideBar currentDestination="Support" list={[]} />
            <Toaster position="bottom-left" reverseOrder={false} />
            <div className="md:p-4 sm:ml-64">
                <div className="p-2 md:mt-3">
                    <h4>Support </h4>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >

                        {currentUser?.type != 0 && (
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <button
                                    onClick={() => setOpenDialog(true)}
                                    type="button"
                                    className=" text-white bg-[#212e79] hover:bg-[#1d2a72] focus:ring-4 focus:outline-none focus:ring-[#30409c]  rounded-lg text-sm px-5 py-2.5  my-1 text-center inline-flex items-center"
                                >
                                    Add Ticket
                                </button>
                            </Grid>
                        )}


                    </Grid>

                    <SupportTable tickets={tickets} />
                </div>
            </div>
            <Dialog open={openDialog}>
                <AddTicket setOpenDialog={setOpenDialog} toast={toast} setTickets={setTickets} tickets={tickets} />
            </Dialog>
        </div >
    )
}



export default Support;