import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, TableContainer,Table, TableHead, TableRow, TableCell, TableBody, TablePagination} from "@mui/material";
import { useNavigate } from "react-router-dom";

function UsersTable(props) {

  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handelOnClick = (value) => {
    navigate(value.ref_id, { state: { user_id: value.id ,type:value.type,user:value} });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div className="my-2">
      <TableContainer sx={{ height: "100%" }}>
      <Table stickyHeader aria-label="sticky table"> 
      <TableHead >
        <TableRow>
          <TableCell align="center"></TableCell>
          <TableCell align="center">ID</TableCell>
          <TableCell align="center">Name</TableCell>
          <TableCell align="center">Email</TableCell>
          <TableCell align="center"> Phone Number</TableCell>
          <TableCell align="center"></TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
          {props.users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index)=>{
            return(
             <TableRow tabIndex={-1} hover role="checkbox" >
              <TableCell key={Math.random() + row.id}  style={{ textAlign: "center" }}>{index +1}</TableCell>
              <TableCell key={Math.random() + row.id}  style={{ textAlign: "center" }}>{row.ref_id}</TableCell>
              <TableCell key={Math.random() + row.id}  style={{ textAlign: "center" }}>{row.name}</TableCell>
              <TableCell key={Math.random() + row.id}  style={{ textAlign: "center" }}>{row.email}</TableCell>
              <TableCell key={Math.random() + row.id}  style={{ textAlign: "center" }}>{row.phone_number}</TableCell>
              <TableCell key={Math.random() + row.id}  style={{ textAlign: "center" }}> <IconButton
                  onClick={(e) => {
                    handelOnClick(row);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
                </TableCell>
             </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <TablePagination
            rowsPerPageOptions={[10, 25, 100, 500]}
            component="div"
            count={props.users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </TableContainer>
    </div>
  );
}

export default UsersTable;
