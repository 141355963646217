
import { HTTP } from "./api";

export default {
  getMyAnnotators(data) {

    return HTTP.get(`admin/annotator/${data}`);
  },

  createAnnotator(data) {
    return HTTP.post("admin/create-user", data);
  },
  createImageProject(data) {
    return HTTP.post("project/image", data);
  },
  createTextProject(data) {
    return HTTP.post("project/text", data);
  },
  createNewTask(data) {
    return HTTP.post("task", data);
  },

  createNewUser(data) {
    return HTTP.post("admin/create-user", data);
  },

  getMyQualityAssurance(owner_id) {
    return HTTP.get(
      `admin/quality-assurance/${owner_id}`
    );
  },

  getMyQualityAssuranceApprovedTasks(quality_assurance_id){
   return HTTP.get(`admin/quality-assurance-approved-tasks/${quality_assurance_id}`);
  },

  getAnnotatorFinishedTasks(annotator_id){
    return HTTP.get(`admin/annotator-finished-tasks/${annotator_id}`);
  }
  
};
