import { HTTP } from "./api";

export default {


    createAdmin(data){
        return HTTP.post("super-admin/admin",data);
    },

    getAllAdmin(){
        return HTTP.get("super-admin/admin");
    },

    updateAdmin(data,id){
        return HTTP.put(`super-admin/admin/edit/${id}`,data);
    }


}