import React, { useEffect, useState } from "react";
import sorteraLogo from "../assets/images/sorteraLogo.png";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import toast, { Toaster } from "react-hot-toast";
import {
  adminFeatures,
  annotatorFeatures,
  qualityAssuranceFeatures,
  sorteraAdmin
} from "../Data/usersFeatures";
import auth from "../api/auth";
function SideBar(props) {
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const [currentDestination, setCurrentDestination] = useState(
    props.currentDestination
  );

  const handelOnClickAccount = () => {
    let currentUserType = JSON.parse(localStorage.getItem("user")).type

    if (currentUserType == 0) {
      navigate("/sortera-admin/profile");
    }
    else if (currentUserType == 1) {

      navigate("/admin/profile");
    } else if (currentUserType == 2) {

      navigate("/quality-assurance/profile");
    } else if (currentUserType == 3) {
      navigate("/annotator/profile");
    }

  };

  const logout = () => {
    auth.logout()
      .then((response) => {
        localStorage.clear();
        window.location = "/login";
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          toast.error("Error! please try again", {
            style: { padding: "15px" },
          });
          
        } else {
          window.location = "/login";
          localStorage.clear();
        }
      })
  }

  const support = () => {

    navigate("/support");

  }
  useEffect(() => {


    const getUserData = () => {

      let currentUser = JSON.parse(localStorage.getItem("user"));

      if (currentUser == null) {
        window.location.href = "/login"
      } else {
        if(currentUser.type == 0){
          setList(sorteraAdmin);
        }
       else if (currentUser.type == 1) {
          setList(adminFeatures);
        } else if (currentUser.type == 2) {
          setList(qualityAssuranceFeatures);
        } else if (currentUser.type == 3) {
          setList(annotatorFeatures);
        }
      }

    };
    getUserData();
  }, []);

  return (
    <div>
      {/* Nav Bar  */}
      <Toaster position="bottom-left" reverseOrder={false} />
      <nav className=" top-0 w-full  bg-white border-b border-gray-200 md:hidden">
        <div className="px-3 py-3 lg:px-5 lg:pl-3 ">
          <div className=" items-center justify-between ">
            <div className="flex items-center justify-start ">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                onClick={() => setOpen(!open)}
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
              >
                <FormatAlignLeftIcon />
              </button>

              <img
                src={sorteraLogo}
                className="h-10 mr-3"
                alt="sortera Logo"
              />
              <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap text-black">
                Sortera
              </span>

              <img
                className="w-12 h-12 rounded-full absolute m-2 right-0 "
                src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                alt="Rounded avatar"
                onClick={handelOnClickAccount}
                style={{ cursor: "pointer" }}
              />
            </div>

            <nav
              className={`flex bg-white   w-auto items-center transition-transform  ${open ? "block" : "hidden"
                }`}
            >
              <ul className="text-base w-full ">
                {list.map((item, index) => (
                  <li key={index}>
                    <a
                      onClick={() => {
                        navigate(item.destination);
                      }}
                      className="block px-5 py-2  text-gray-700 hover:bg-gray-100 "
                      role="menuitem"
                    >
                      {item.title}
                    </a>
                  </li>
                ))}

                <li>
                  <a
                    onClick={support}
                    className="block px-5 py-2  text-gray-700 hover:bg-gray-100 "
                    role="menuitem"
                  >
                    Support
                  </a>
                </li>

                <li>
                  <a
                    onClick={logout}
                    className="block px-5 py-2  text-gray-700 hover:bg-gray-100 "
                    role="menuitem"
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 w-64 h-screen  transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 "
        aria-label="Sidebar"
      >
        <div className="flex ml-2 md:mr-24">
          <img src={sorteraLogo} className="h-[72px]" alt="sortera Logo" />
          <span className="self-center text-l font-semibold sm:text-2xl whitespace-nowrap text-black">
            Sortera
          </span>
        </div>

        <div className="h-full px-3 pb-4 overflow-y-auto   border-t border-gray-900/10 ">
          <ul className="space-y-2 font-medium p-2">
            {list.map((item, index) => (
              <li key={index}>
                <a
                  onClick={() => {
                    navigate(item.destination);
                  }}
                  className={`flex items-center p-2 ${item.title == currentDestination
                    ? "bg-gray-100"
                    : "text-black"
                    } rounded-lg  hover:bg-gray-100 `}
                >
                  {item.icon}
                  <span className="flex-1 ml-3 text-black">{item.title}</span>
                </a>
              </li>
            ))}


            <li>
              <a
                onClick={support}
                className={`flex items-center p-2  ${currentDestination === "Support" ? 'bg-gray-100' : "text-black"}  rounded-lg  hover:bg-gray-100 `}
                role="menuitem"
              >
                <SupportAgentIcon />
                <span className="flex-1 ml-3 text-black">Support</span>
              </a>
            </li>

            <li>
              <a
                onClick={logout}
                className={`flex items-center p-2  text-black rounded-lg  hover:bg-gray-100 `}
              >
                <LogoutIcon />
                <span className="flex-1 ml-3 text-black">Logout</span>
              </a>
            </li>

          </ul>
        </div>
        <div
          className="absolute bottom-5 h-8 w-full  p-10 flex justify-center items-center"
          onClick={handelOnClickAccount}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}

          >
            <img
              className="w-12 h-12 rounded-full"
              src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
              alt="Rounded avatar"
            />
            <h6 className="m-2">
              {JSON.parse(localStorage.getItem("user"))?.name}
            </h6>
            <h6>{JSON.parse(localStorage.getItem("user"))?.email}</h6>
          </Grid>
        </div>
      </aside>
    </div>
  );
}

export default SideBar;
