import axios from "axios";
import { HTTP } from "./api";

export default {
  getTasks(id) {
    return HTTP.get(
      `quality-assurance/tasks/${id}`
    );
  },
  approveTask(data) {
    return HTTP.post(
      "quality-assurance/approved-task",
      data
    );
  },
  rejectTask(id) {
    return HTTP.post(
      `quality-assurance/reject-task/${id}`
    );
  },
  getProjects(id) {
    return HTTP.get(
      `quality-assurance/project/${id}`
    );
  },

  getProjectTasks(data){
    return HTTP.get(`quality-assurance/project-tasks/${data.project_id}/${data.quality_assurance_id}`)
  }
};
