import { Box, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import SideBar from "../../Components/SideBar";
import TasksTable from "../../Components/TasksTable";
import { adminFeatures } from "../../Data/usersFeatures";
import CreateTask from "../../Components/CreateTask";
import user from "../../api/user";
import Message from "../../Components/PageMessage";
import toast, { Toaster } from "react-hot-toast";

function TaskComponent(props) {
  const [currentData, setCurrentData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [projects, setProjects] = useState([]);
  const[isLoadingData, setIsLoadingData] = useState(true);

  const handelSelectProject = (event) => {
    setIsLoadingData(true);
    let projectID = event.target.value;
    getProjectTasks(projectID);
  };

  const getProjectTasks = async (id) => {
    try {
      user.getProjectTasks(id).then((response) => {
        setCurrentData(response.data.tasks);
        setIsLoadingData(false);

      });
    } catch (error) {
      if (error.code === "ERR_NETWORK") {

        toast.error("Error! please try again", {
          style: { padding: "15px" },
        });

      } else if (error.response.status === 401) {
        localStorage.clear();
        window.location = "/login"
      } else {


        toast.error("Error! please try again", {
          style: { padding: "15px" },
        });
      }
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const getProjectsData = async () => {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      let userID = currentUser.id;

      user
        .getMyProjects(userID)
        .then((response) => {

          setProjects(response.data.projects);
          if (response.data.projects.length > 0) {
            getProjectTasks(response.data.projects[0].id);
          }
          setIsLoadingData(false);
        })
        .catch((error) => {
          if (error.code === "ERR_NETWORK") {

            toast.error("Error! please try again", {
              style: { padding: "15px" },
            });

          } else if (error.response.status === 401) {
            localStorage.clear();
            window.location = "/login"
          } else {


            toast.error("Error! please try again", {
              style: { padding: "15px" },
            });
          }
        });
    };
    getProjectsData();
  }, []);
  return (
    <div className="App" style={{ height: "100vh" }}>
      <Toaster position="bottom-left" reverseOrder={false} />
      <div>
        <SideBar currentDestination="Task" list={adminFeatures} />
        <div className="md:p-4 sm:ml-64">
          <div className="p-2 md:mt-3">
            <h4>Tasks </h4>

            {projects.length > 0 && (
              <div className="w-full">
                <button
                  onClick={() => setOpenDialog(true)}
                  type="button"
                  className=" text-white bg-[#212e79] focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center  float-right m-2"
                >
                  New Task
                </button>
              </div>
            )}

            <div className="w-full">
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {projects.length > 0 && (
                    <Grid item>
                      <select
                        id="small"
                        onChange={handelSelectProject}
                        className="border border-gray-200 bg-gray-200 text-gray-700 rounded-md px-4 py-2 m-2 transition duration-500 ease select-none focus:outline-none focus:shadow-outline"
                      >
                        {projects.map((project) => (
                          <option key={project.id} value={project.id}>
                            {project.name}
                          </option>
                        ))}
                      </select>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {isLoadingData ? (
                <Box sx={{ display: 'flex', justifyContent: "center", height: "80vh", width: "100%", alignItems: "center" }}>
                  <CircularProgress style={{ color: "#212e79" }} size="90px" />
                </Box>
              ) : (
                <>
                  {projects.length <= 0 ? (
                    <Message type={0} />
                  ) : (
                    <>
                      <TasksTable data={currentData} type={0} />
                    </>
                  )}
                </>
              )}


            </div>
          </div>
        </div>

        {/* Add New Task  */}

        <CreateTask
          project={projects}
          openDialog={openDialog}
          handleClose={handleClose}
          toast={toast}
          setOpenDialog={setOpenDialog}
        />
      </div>
    </div>
  );
}
export default TaskComponent;
