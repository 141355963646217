import {
  TextField,
  Grid,
  Button,
  Paper,
  ListItem,
  List,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { v4 as uuidv4 } from "uuid";
function CreateClassification(props) {
  const [color, setColor] = useState("#6590D5");
  const [classificationsTitle, setClassificationsTitle] = useState("");

  const handelAddClassificationsBtn = () => {
    const newClassifications = {
      id: uuidv4(),
      color: color,
      title: classificationsTitle,
    };

    props.setClassifications((previous) => [...previous, newClassifications]);
    props.setDisabledBtn(false);
    setClassificationsTitle("");
    setColor("#6590D5");
  };

  const handelDeleteClassifications = (classification) => {
    props.setClassifications((classifications) =>
      classifications.filter((value) => {
        return value.id != classification.id;
      })
    );

    if (props.classifications.length <= 1) {
      props.setDisabledBtn(true);
    }
  };
  return (
    <div>
      <div style={{ padding: "10px" }}>
        <TextField
          label="Title"
          variant="outlined"
          value={classificationsTitle}
          style={{ width: "90%" }}
          onChange={(e) => {
            setClassificationsTitle(e.target.value);
          }}
        />

        <label
          htmlFor="first-name"
          className="block text-sm font-medium leading-6 text-gray-900 m-1"
        >
          Color
        </label>

        <input
          type="color"
          value={color}
          onChange={(e) => {
            setColor(e.target.value);
          }}
          style={{
            width: "100px",
            height: "100px",
            border: "none",
            borderRadius: "25px",
            overflow: "hidden",
            backgroundColor: "transparent",
            borderColor: "transparent",
          }}
        />
      </div>
      <Button
        variant="outlined"
        onClick={handelAddClassificationsBtn}
        disabled={color === "" || classificationsTitle === ""}
      >
        Submit
      </Button>
      {props.classifications.length > 0 && (
        <Paper
          variant="outlined"
          square
          style={{ width: "100%", margin: "10px", borderRadius: "5px" }}
        >
          <List>
            <ListItem>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                columns={12}
              >
                {props.classifications.map((classification, index) => (
                  <Grid columns={12} container direction="row" key={index}>
                    <Grid item xs={1}>
                      <CircleIcon
                        style={{ color: `${classification.color}` }}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <p>{classification.title}</p>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          handelDeleteClassifications(classification);
                        }}
                      >
                        <DeleteIcon
                          style={{
                            color: "red",
                          }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </ListItem>
          </List>
        </Paper>
      )}
    </div>
  );
}
export default CreateClassification;
