import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  OutlinedInput,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  Chip,
  Divider,
  Paper,
  ListItem,
  List,
  IconButton,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { stepsData } from "../Data/FakeData";
import admin from "../api/admin";
import UploadFile from "./UploadFile";
import { v4 as uuidv4 } from "uuid";
import CreateClassification from "./CreateClassifications";

function CreateProject(props) {
  const [projectName, setProjectName] = useState("");
  const [projectType, setProjectType] = useState(0);
  const [annotatorType, setAnnotatorType] = useState("Custom");
  const [selectedAnnotators, setSelectedAnnotators] = useState([]);
  const [color, setColor] = useState("#6590D5");
  const [classificationsTitle, setClassificationsTitle] = useState("");
  const [classifications, setClassifications] = useState([]);
  const [textData, setTextData] = useState("");
  const [breakPoint, setBreakPoint] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectFiles, setProjectFiles] = useState([]);
  const [annotatorsList, setAnnotatorsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [selectedOneAnnotator, setSelectedOneAnnotator] = useState("");
  const [error, setError] = useState([]);

  const handleNext = () => {
    props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (projectFiles.length > 0) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
    if (classifications.length > 0) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
    if (props.activeStep + 1 >= stepsData.length) {
      props.setShowAddNewTask(false);
    }
  };

  const handleBack = () => {
    props.setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (
      projectFiles.length > 0 ||
      (projectName != "" && projectDescription != "")
    ) {
      setDisabledBtn(false);
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (projectType === 0) {
      setSelectedAnnotators(event.target.value);
    } else {
      setSelectedOneAnnotator(event.target.value);
      setSelectedAnnotators([event.target.value]);
    }
  };

  const createProject = async () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("name", projectName);
    formData.append("description", projectDescription);
    formData.append("owner_id", JSON.parse(localStorage.getItem("user"))?.id);
    formData.append("type", projectType);
    formData.append("classification", JSON.stringify(classifications));
    formData.append("owner_name", "admin");

    if (projectType === 0) {
      for (let i = 0; i < projectFiles.length; i++) {
        formData.append("images", projectFiles[i]);
      }
    } else {
      for (let i = 0; i < projectFiles.length; i++) {
        formData.append("file", projectFiles[i]);
      }
    }

    if (selectedAnnotators.length > 0) {
      formData.append("project_annotators", JSON.stringify(selectedAnnotators));
    } else {
      formData.append("project_annotators", null);
    }

    if (projectType === 0) {
      admin
        .createImageProject(formData)
        .then((response) => {
          // return all the fields to the default
          setLoading(false);
          props.setShowAddNewTask(false);
          setProjectName("");
          setProjectDescription("");
          setProjectFiles([]);
          setSelectedAnnotators([]);

          window.location.reload();
          // alert
          props.toast.success(`project created successfully`, {
            style: { padding: "15px" },
          });
        })
        .catch((error) => {

          if (error.code === "ERR_BAD_REQUEST") {

            if (error.response.status === 422) {
              setError(error.response.data.validationRequestResult.errors);
              props.toast.error(`Pleas check your fields`, {
                style: { padding: "15px" },
              });
            } else if (error.response.status === 400) {

              if (error.response.data.code === 40003) {
                props.toast.error(error.response.data.message, {
                  style: { padding: "15px" },
                });
              } else {
                props.toast.error("Error, Pleas try again", {
                  style: { padding: "15px" },
                });
              }
            } else {
              props.toast.error(`there is an error please try again`, {
                style: { padding: "15px" },
              });
            }
          } else {
            props.toast.error(`there is an error please try again`, {
              style: { padding: "15px" },
            });
          }
          setLoading(false);
        });
    } else {
      admin
        .createTextProject(formData)
        .then((response) => {
          // return all the fields to the default
          setLoading(false);
          props.setShowAddNewTask(false);
          setProjectName("");
          setProjectDescription("");
          setProjectFiles([]);
          setSelectedAnnotators([]);

          window.location.reload();
          // alert
          props.toast.success(`project created successfully`, {
            style: { padding: "15px" },
          });
        })
        .catch((error) => {


          if (error.code === "ERR_BAD_REQUEST") {

            if (error.response.status === 422) {
              setError(error.response.data.validationRequestResult.errors);
              props.toast.error(`Pleas check your fields`, {
                style: { padding: "15px" },
              });
            } else if (error.response.status === 400) {

              if (error.response.data.code === 40003) {
                props.toast.error(error.response.data.message, {
                  style: { padding: "15px" },
                });
              } else {
                props.toast.error("Error, Pleas try again", {
                  style: { padding: "15px" },
                });
              }
            } else {
              props.toast.error(`there is an error please try again`, {
                style: { padding: "15px" },
              });
            }
          } else {
            props.toast.error(`there is an error please try again`, {
              style: { padding: "15px" },
            });
          }
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const getAnnotatorsData = async () => {
      const data = JSON.parse(localStorage.getItem("user")).id;

      admin
        .getMyAnnotators(data)
        .then((response) => {
          setAnnotatorsList(response.data.annotators);
        })
        .catch((error) => {
          props.toast.error(`there is an error please try again`, {
            style: { padding: "15px" },
          });
        });
    };

    getAnnotatorsData();
  }, []);
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item md={6} xs={12}>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={props.activeStep} orientation="vertical">
            {stepsData.map((step, index) => (
              <Step key={step.title}>
                <StepLabel
                  optional={
                    index === stepsData.length - 1 ? (
                      <Typography variant="caption">Last Step</Typography>
                    ) : null
                  }
                >
                  {step.title}
                </StepLabel>

                <StepContent>
                  <Typography>{step.description}</Typography>
                  {index == 0 && ( // project information
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-6">
                        <TextField
                          label="Project Name"
                          variant="outlined"
                          value={projectName}
                          error={error.some(error => error.path === "name")}
                          helperText={error.some(error => error.path === "name") && error.map((error) => { if (error.path === "name") { return error.msg } })}
                          style={{ width: "90%" }}
                          onChange={(e) => {
                            setProjectName(e.target.value);
                            if (
                              projectDescription != "" &&
                              e.target.value != ""
                            ) {
                              setDisabledBtn(false);
                            } else {
                              setDisabledBtn(true);
                            }
                          }}
                        />
                      </div>
                      <div className="col-span-full">
                        <TextField
                          label="Description"
                          style={{ width: "90%" }}
                          multiline
                          maxRows={4}
                          variant="outlined"
                          error={error.some(error => error.path === "description")}
                          helperText={error.some(error => error.path === "description") && error.map((error) => { if (error.path === "description") { return error.msg } })}
                          value={projectDescription}
                          onChange={(e) => {
                            setProjectDescription(e.target.value);
                            if (projectName != "" && e.target.value != "") {
                              setDisabledBtn(false);
                            } else {
                              setDisabledBtn(true);
                            }
                          }}
                        />
                      </div>

                      <div className="col-span-full">
                        <FormControl>
                          <Typography>Assets Type:</Typography>

                          <RadioGroup row name="row-radio-buttons-group">
                            <FormControlLabel
                              value={0}
                              control={
                                <Radio
                                  value={0}
                                  checked={projectType == 0}
                                  onChange={(e) => {
                                    setProjectType(e.target.value);
                                    setProjectFiles([]);
                                  }}
                                />
                              }
                              label="Image"
                            />
                            <FormControlLabel
                              value={1}
                              control={
                                <Radio
                                  value={1}
                                  checked={projectType == 1}
                                  onChange={(e) => {
                                    setProjectType(e.target.value);
                                    setProjectFiles([]);
                                  }}
                                />
                              }
                              label="Text"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  )}

                  {/* ْupload Data  */}

                  {index === 1 && (
                    <div className="col-span-full">
                      <UploadFile
                        enableMultiple={projectType == 1 ? false : true}
                        setProjectFiles={setProjectFiles}
                        toast={props.toast}
                        type={parseInt(projectType)}
                        setDisabledBtn={setDisabledBtn}
                      />
                    </div>
                  )}
                  {/* Make the classifications  */}
                  {index == 2 && (
                    <CreateClassification
                      classifications={classifications}
                      setClassifications={setClassifications}
                      setDisabledBtn={setDisabledBtn}
                    />
                  )}

                  {index == 3 && (
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <FormControl>
                        <RadioGroup row name="row-radio-buttons-group">
                          <FormControlLabel
                            value="Random"
                            control={
                              <Radio
                                value="Random"
                                checked={annotatorType == "Random"}
                                onChange={(e) =>
                                  setAnnotatorType(e.target.value)
                                }
                              />
                            }
                            label="Random"
                          />
                          <FormControlLabel
                            value="Custom"
                            control={
                              <Radio
                                value="Custom"
                                checked={annotatorType == "Custom"}
                                onChange={(e) =>
                                  setAnnotatorType(e.target.value)
                                }
                              />
                            }
                            label="Custom"
                          />
                        </RadioGroup>
                      </FormControl>
                      {annotatorType == "Custom" && (
                        <FormControl sx={{ m: 1, width: 300 }}>
                          <InputLabel id="demo-multiple-name-label">
                            Annotators
                          </InputLabel>
                          {projectType === 0 ? (
                            <Select
                              labelId="demo-multiple-chip-label"
                              id="demo-multiple-chip"
                              multiple
                              value={selectedAnnotators}
                              onChange={handleChange}
                              input={
                                <OutlinedInput
                                  id="select-multiple-chip"
                                  label="Annotator "
                                />
                              }
                              renderValue={(selected) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {selected.map((value) => (
                                    <Chip key={value.id} label={value.name} />
                                  ))}
                                </Box>
                              )}
                            >
                              {annotatorsList.map((annotator) => (
                                <MenuItem
                                  key={annotator.id}
                                  value={annotator}
                                >
                                  {annotator.name}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedOneAnnotator}
                              label="Annotators"
                              onChange={handleChange}
                            >
                              {annotatorsList.map((annotator) => (
                                <MenuItem value={annotator} key={annotator.id}>
                                  {annotator.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </FormControl>
                      )}
                    </Grid>
                  )}
                </StepContent>
                {props.activeStep === index && (
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <LoadingButton
                        size="medium"
                        loading={loading}
                        disabled={disabledBtn}
                        style={{ margin: "10px", borderRadius: "6px", padding: '6px', backgroundColor: disabledBtn ? "#e0e0e0" : "#212e79", color: disabledBtn ? "#a6a6a6" : "white" }}
                        className="flex  justify-center rounded-md  m-[10px] px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm  "
                        onClick={
                          index === stepsData.length - 1
                            ? createProject
                            : handleNext
                        }
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {index === stepsData.length - 1 ? "Finish" : "Continue"}
                      </LoadingButton>
                      {index != 0 && (

                        <button
                          disabled={index === 0}
                          className="flex justify-center rounded-md  m-[10px] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-[#d62525] disabled:bg-[#e8e4e4] "


                          onClick={handleBack}
                        >
                          Back
                        </button>
                      )}
                    </div>
                  </Box>
                )}
              </Step>
            ))}
          </Stepper>
        </Box>
      </Grid>
    </Grid>
  );
}
export default CreateProject;
