import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Chip, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";


function SupportTable(props) {
    const navigate = useNavigate();

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handelOnClick = (value) => {
        navigate(`/support/ticket/${value.ref_id}`);
    };
    return (
        <div className="my-2">

            <TableContainer sx={{ height: "100%" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">ID</TableCell>
                            <TableCell align="center">Title</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Created at</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.tickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <TableRow tabIndex={-1} hover role="checkbox" >
                                    <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>{index + 1}</TableCell>
                                    <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>{row.ref_id}</TableCell>
                                    <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>{row.title}</TableCell>
                                    <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>
                                        <Chip label={row.status == 0 ? "In Progress" : "Done"} style={{ backgroundColor: row.status == 0 ? "#fdecc8" : "#c0e4d4", color: row.status == 0 ? "#402c1b" : "#1d3829" }} />
                                    </TableCell>
                                    <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>{row.created_at}</TableCell>
                                    <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}> <IconButton
                                        onClick={(e) => {
                                            handelOnClick(row);
                                        }}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                    </TableCell>
                                </TableRow>

                            )
                        })}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100, 500]}
                    component="div"
                    count={props.tickets.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </div>
    );
}

export default SupportTable;