import React from "react";
import projectsIcon from "../assets/icons/projects.png";
import tasksIcon from "../assets/icons/task.png";
import annotatorsIcon from "../assets/icons/annotators.png";
function Message(props) {
  return (
    <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">

        
        {props.type == 0 ? (
          <>
            {/*  project message */}
            <div className="p-10 w-64 border-4 bg-gray-200 rounded-full">
              <img src={projectsIcon} />
            </div>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Sorry, we couldn’t find projects .
            </p>
          </>
        ) : (
          <>
            {props.type == 1 ? (
              <>
                {/*  task message */}
                <div className="p-10 w-64 border-4 bg-gray-200 rounded-full">
                  <img src={tasksIcon} />
                </div>
                <p className="mt-6 text-base leading-7 text-gray-600">
                  Sorry, we couldn’t find tasks.
                </p>
              </>
            ) : (
              <>
                {/* Annotator Message */}
                <div className="p-10 w-64 border-4 bg-gray-200 rounded-full">
                  <img src={annotatorsIcon} />
                </div>

                <p className="mt-6 text-base leading-7 text-gray-600">
                  Sorry, we couldn’t find{" "}
                  {props.type == 2 ? "annotators" : "Quality Assurance"}.
                </p>
              </>
            )}
          </>
        )}

        <div className="mt-10 flex items-center justify-center gap-x-6"></div>
      </div>
    </main>
  );
}

export default Message;
