import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Message from "./PageMessage";

function ProjectTable(props) {

  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getType = (type) => {
    switch (type) {
      case 0:
        return "Image";
      case 1:
        return "Text";
      default:
        return "";
    }
  };


  const handelOnClick = (value) => {
    
    navigate("/project/" + value.ref_id, { state: { project_id: value.id , project:value } });
  };
  return (
    <div className="my-2">

      {props.projects?.length == 0 ?

        (
          <Message type={0} />
        ) :
        (
          <>
            <TableContainer sx={{ height: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Type</TableCell>
                    <TableCell align="center">Owner</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    return (
                      <TableRow tabIndex={-1} hover role="checkbox" >
                        <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>{index + 1}</TableCell>
                        <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>{row.ref_id}</TableCell>
                        <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>{row.name}</TableCell>
                        <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>{getType(row.type)}</TableCell>
                        <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>{row.owner_name}</TableCell>
                        <TableCell key={Math.random() + row.id} style={{ textAlign: "center" }}>
                          <IconButton
                            onClick={(e) => {
                              handelOnClick(row);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100, 500]}
                component="div"
                count={props.projects.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </>
        )}
    </div>
  );
}
export default ProjectTable;
