import { Chip, Divider, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Toaster ,toast} from 'react-hot-toast';
import support from '../api/support';
import SideBar from './SideBar';
import statusIcon from "../assets/icons/status.png"
import TitleIcon from '@mui/icons-material/Title';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
function TicketDetails() {


    const [ticketInfo, setTicketInfo] = useState([]);

    const handleSolveTicket = async() =>{

        await support.solveTicket(ticketInfo.id)
        .then(()=>{
        toast.success("Ticket Solved Successfully", {
                style: { padding: "15px" },
            });
        }).catch((error)=>{

            console.log(error);

            toast.error("Error, Please Try again", {
                style: { padding: "15px" },
            });
        })
    }


    useEffect(() => {

        const getData = async () => {
            const ticketID = window.location.href.split("/")[5];
            support.getTicketById(ticketID).then((response) => {
                setTicketInfo(response.data[0].ticket);
            })
        }
        getData();

    }, [])
    return (

        <div className="App" style={{ minHeight: "100vh" }}>
            <SideBar currentDestination="Support" list={[]} />
            <Toaster position="bottom-left" reverseOrder={false} />
            <div className="md:p-4 sm:ml-64">
                <div className="p-2 md:mt-3">

                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <div><span className=" text-gray-500 text-2xl">{ticketInfo.ref_id}</span></div>

                        {JSON.parse(localStorage.getItem("user")).type == 0 && (

                            <button
                            onClick={handleSolveTicket}
                                type="button"
                                className=" text-white bg-[#212e79] hover:bg-[#1d2a72] focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center float-right m-2"
                            >
                                Solve
                            </button>
                        )}

                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <div className="flex  items-center drop-shadow-md m-2">
                            <TitleIcon sx={{ width: "20px", height: "20px" }} />
                            <span className=" text-gray-500 text-lg">Title:</span>
                            <span className='p-2'>{ticketInfo.title}</span>
                        </div>

                        <div className="flex items-center drop-shadow-md m-2">
                            <img src={statusIcon} width="20px" height="20px" />
                            <span className=" text-gray-500 p-2 text-lg">Status:</span>
                            
                            <span className='p-2'>


   
                                <Chip label={<div class="flex flex-wrap">


                                    <span className={ticketInfo.status == 0 ? 'text-5xl text-[#cb9433] m-1' : "text-5xl text-[#6c9b7d] m-1"}>
                                        •
                                    </span>
                                    <span className="flex items-center justify-center">
                                        {ticketInfo.status == 0 ? "In Progress" : "Done"}
                                    </span>


                                </div>} style={{ backgroundColor: ticketInfo.status == 0 ? "#fdecc8" : "#c0e4d4", color: ticketInfo.status == 0 ? "#402c1b" : "#1d3829" }} >
                                </Chip>

                            </span>
                        </div>

                        <div className="flex  items-center drop-shadow-md m-2">
                            <PersonIcon sx={{ width: "20px", height: "20px", margin: "2px" }} />
                            <span className=" text-gray-500 text-lg">Owner:</span>
                            <span className='p-2'>{ticketInfo.owner_name}</span>
                        </div>

                        <div className="flex  items-center drop-shadow-md m-2">
                            <CalendarMonthIcon sx={{ width: "20px", height: "20px", margin: "2px" }} />
                            <span className=" text-gray-500 text-lg">Create At:</span>
                            <span className='p-2'>{ticketInfo.created_at}</span>
                        </div>


                        <div className=' w-[100%]'>

                            <hr class="my-19 h-0.5 border-t-0 bg-slate-950 opacity-25 w-full" />
                            <div class="container mx-2">
                                <span className=" text-gray-500 text-lg py-2">Description:</span>
                                <p >
                                    {ticketInfo.description}
                                </p>
                            </div>
                        </div>
                    </Grid>


                </div>
            </div>
        </div>

    )
}

export default TicketDetails;