import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  TableBody,
  Table,
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  Chip,
  IconButton,
} from "@mui/material";
import Message from "./PageMessage";
import { useNavigate } from "react-router-dom";

function TasksTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();
  const handelOnClick = (value) => {
    switch (value.project_type) {
      case 0:
        navigate(
          `/task/image/${value.project_name.replaceAll(" ", "-")}/${
            value.ref_id
          }`
        );
        break;
      case 1:
        navigate(
          `/task/text/${value.project_name.replaceAll(" ", "-")}/${
            value.ref_id
          }`
        );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getType = (type) => {
    switch (type) {
      case 0:
        return "Image";
      case 1:
        return "Text";
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return {
          label: "Not Started",
          color: "black",
          backgroundColor: "#ebebeb",
        };
      case 1:
        return {
          label: "Unapproved",
          color: "#eeb969",
          backgroundColor: "#fef5c7",
        };
      case 2:
        return {
          label: "Done",
          color: "#14532d",
          backgroundColor: "#d8f6e2",
        };

      case 3:
        return {
          label: "Rejected",
          color: "#7f1c1d",
          backgroundColor: "#fee4e5",
        };
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      {/* <Paper sx={{ overflow: "hidden" }}> */}
      {props.data.length > 0 ? (
        <TableContainer sx={{ height: "100%" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">id</TableCell>

                <TableCell align="center">Project</TableCell>
                {props.type === 0 && (
                  <TableCell align="center">Assigned To</TableCell>
                )}
                <TableCell align="center">Type</TableCell>

                <TableCell align="center">Status</TableCell>

                <TableCell align="center">Created At</TableCell>

                {(props.type === 1 || props.type === 0) && (
                  <TableCell align="center"></TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell
                        key={Math.random() + row.id}
                        style={{ textAlign: "center" }}
                      >
                        {index + 1}
                      </TableCell>

                      <TableCell
                        key={Math.random() + row.id}
                        style={{ textAlign: "center" }}
                      >
                        {row.ref_id}
                      </TableCell>
                      <TableCell
                        key={Math.random() + row.id}
                        style={{ textAlign: "center" }}
                      >
                        {row.project_name}
                      </TableCell>

                      {props.type === 0 && (
                        <TableCell
                          key={Math.random() + row.id}
                          style={{ textAlign: "center" }}
                        >
                          {row.owner_name}
                        </TableCell>
                      )}

                      <TableCell
                        key={Math.random() + row.id}
                        style={{ textAlign: "center" }}
                      >
                        {getType(row.project_type)}
                      </TableCell>
                      <TableCell
                        key={Math.random() + row.id}
                        style={{ textAlign: "center" }}
                      >
                        <Chip
                          label={getStatus(row.status).label}
                          style={{
                            color: getStatus(row.status).color,
                            backgroundColor: getStatus(row.status)
                              .backgroundColor,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        key={Math.random() + row.id}
                        style={{ textAlign: "center" }}
                      >
                        {row.created_at}
                      </TableCell>

                      {(props.type === 1 || props.type == 0) && (
                        <TableCell
                          key={Math.random() + row.id}
                          style={{ textAlign: "center" }}
                        >
                          <IconButton
                            onClick={(e) => {
                              handelOnClick(row);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 500]}
            component="div"
            count={props.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      ) : (
        <Message type={1} />
      )}
      {/* </Paper> */}
    </div>
  );
}
export default TasksTable;
