import axios from "axios";
import { HTTP } from "./api";

export default {
  login(data) {
    return axios.post("http://127.0.0.1:3001/api/v1/auth/login", data);
  },
  verifyOtp(data) {
    return axios.post("http://127.0.0.1:3001/api/v1/auth/verify-otp", data);
  },
  setPassword(data) {
    return axios.post("http://127.0.0.1:3001/api/v1/auth/reset-password", data);
  },

  logout() {
    console.log(localStorage.getItem("token"));
    return HTTP.post("auth/logout")

  }
};
