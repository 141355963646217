import { LoadingButton } from "@mui/lab";
import { DialogActions, DialogContent, TextField ,Button} from "@mui/material";
import React, { useState } from "react";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import admin from "../../api/admin";

function AddQualityAssurance(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error,setError] = useState([]);
  const [qualityAssuranceNumbersError, setQualityAssuranceNumbersError] = useState("");
  const createQualityAssurance = async () => {
    setLoading(true);
    const data = {
      name: name,
      email: email,
      phone_number: phoneNumber,
      type: "2",
      owner_id: props.currentUser.id,
    };

    await admin
      .createNewUser(data)
      .then((response) => {
        setLoading(false);
        setName("");
        setEmail("");
        setPhoneNumber("");
        setDisabledBtn(true);
        props.handleClose();
        window.location.reload();
        props.toast.success("Quality Assurance created successfully", {
          style: { padding: "15px" },
        });
      })
      .catch((error) => {
        if(error.code === "ERR_BAD_REQUEST"){
          if(error.response.status === 422){
            setError(error.response.data.validationRequestResult.errors);
           
          } else if(error.response.status === 400) {

            if(error.response.data.code === 40004){
              setError([{ msg:error.response.data.message,path:"email"}]) ;
            } else if(error.response.data.code === 40003){
              setQualityAssuranceNumbersError(error.response.data.message);
            } else{
              props.toast.error("Error, Please Try Again!! ", {
                style: { padding: "15px" },
              });
            }
          } 
          setDisabledBtn(false);
          setLoading(false);

        }else {
        props.toast.error("Error, Please Try Again!! ", {
          style: { padding: "15px" },
        });
        setLoading(false);
        setName("");
        setEmail("");
        setPhoneNumber("");
        setDisabledBtn(true);
      }
      });
  };

  return (
    <>
      <div className="p-4 border-b rounded-t">
        <p className="text-4xl font-thin text-gray-900 ">
          Add Quality Assurance
        </p>
        <p className="text-justify text-gray-500">
          To Add Quality Assurance to Sortera, please fill in all the fields. We
          will send the login information to the annotator.
        </p>
      </div>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          value={name}
          error={error.some(error=>error.path === "name")}
            helperText={error.some(error=>error.path === "name") && error.map((error)=>{if(error.path === "name"){return error.msg}})}
          onChange={(e) => {
            if (e.target.value === "" || email === "" || phoneNumber === "") {
              setDisabledBtn(true);
            } else {
              setDisabledBtn(false);
            }
            setName(e.target.value);
          }}
        />
        <TextField
          autoFocus
          required
          margin="dense"
          id="email"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          error={error.some(error=>error.path === "email")}
            helperText={error.some(error=>error.path === "email") && error.map((error)=>{if(error.path === "email"){return error.msg}})}
          onChange={(e) => {
            if (e.target.value === "" || name === "" || phoneNumber === "") {
              setDisabledBtn(true);
            } else {
              setDisabledBtn(false);
            }
            setEmail(e.target.value);
          }}
        />

        <TextField
          autoFocus
          required
          margin="dense"
          id="phoneNumber"
          label="Phone Number"
          type="text"
          fullWidth
          variant="outlined"
          value={phoneNumber}
          error={error.some(error=>error.path === "phone_number")}
            helperText={error.some(error=>error.path === "phone_number") && error.map((error)=>{if(error.path === "phone_number"){return error.msg}})}
          onChange={(e) => {
            if (e.target.value === "" || email === "" || name === "") {
              setDisabledBtn(true);
            } else {
              setDisabledBtn(false);
            }
            setPhoneNumber(e.target.value);
          }}
        />

{qualityAssuranceNumbersError != "" && (
  <div className="p-2">
   <p className="flex items-center text-sm font-medium text-[#BC1C21]">
      <span className="pr-3">
      <ReportGmailerrorredIcon/>
      </span>
      {qualityAssuranceNumbersError}
   </p>
</div>

)}


        <DialogActions>
          <Button
            style={{ margin: "10px" }}
            onClick={props.handleClose}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <LoadingButton
             style={{ margin: "10px", borderRadius: "6px", padding: '6px', backgroundColor: disabledBtn ? "#e0e0e0" : "#212e79", color: disabledBtn ? "#a6a6a6" : "white" }}
             className="flex  justify-center rounded-md  m-[10px] px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm  "
            size="medium"
            loading={loading}
            variant="contained"
            onClick={createQualityAssurance}
            disabled={disabledBtn}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </>
  );
}

export default AddQualityAssurance;
