import { Button, ButtonGroup, Divider, IconButton, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { annotatorFeatures } from "../Data/usersFeatures";
import SideBar from "./SideBar";
import toast, { Toaster } from "react-hot-toast";

import user from "../api/user";
function Profile() {

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState([]);
  const [disableCancelBtn, setDisabledCancelBtn] = useState(true);


  const cancel = () => {

    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setDisabledBtn(true);
    setDisabledCancelBtn(true);
    setError([]);
  }


  const setPassword = async () => {
    const data = {
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    };
    console.log(data);
    await user
      .resetPassword(data)
      .then((data) => {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setDisabledBtn(true);
        setDisabledCancelBtn(true);
        setError([]);
        toast.success("password reset successfully", {
          style: { padding: "15px" },
        });
      })
      .catch((err) => {
        if(err.code === "ERR_BAD_REQUEST"){
          if(err.response.status === 422){
            setError(err.response.data.validationRequestResult.errors);
          }else if(err.response.status === 400){
            
            if(err.response.data.code === 4001){
              setError([{ msg:err.response.data.message ,path:"current_password"}]) ;
            }else if(err.response.data.code === 4002){
              
              setError([{ msg:err.response.data.message,path:"password_confirmation"}]) ;
            } else {
              toast.error("Time is up you need to login again", {
                style: { padding: "15px" },
              });
              setError([]);
              
            }
          }
          
        }
        //setError(error.response.data.message);
      });
  }
  return (
    <div className="App" style={{ minHeight: "100vh" }}>
      <SideBar currentDestination=" " list={annotatorFeatures} />
      <Toaster position="bottom-left" reverseOrder={false} />
      <div className="md:p-4 sm:ml-64">
        <div className="p-2 md:mt-3">
          <div class="relative max-w-md mx-auto md:max-w-2xl  min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-xl mt-16">
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div class="w-full flex justify-center">
                  <div class="relative">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                      class="shadow-xl rounded-full align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-[150px]"
                    />
                  </div>
                </div>
                <div class="w-full text-center mt-20">
                  <div class="flex justify-center lg:pt-4 pt-8 pb-0">
                    {/* <div class="p-3 text-center">
                      <span class="text-xl font-bold block uppercase tracking-wide text-slate-700">
                        3,360
                      </span>
                      <span class="text-sm text-slate-400">Photos</span>
                    </div> */}
                    {/* <div class="p-3 text-center">
                      <span class="text-xl font-bold block uppercase tracking-wide text-slate-700">
                        10
                      </span>
                      <span class="text-sm text-slate-400">Project</span>
                    </div> */}

                    {/* <div class="p-3 text-center">
                      <span class="text-xl font-bold block uppercase tracking-wide text-slate-700">
                        564
                      </span>
                      <span class="text-sm text-slate-400">Task</span>
                    </div> */}
                  </div>
                </div>
              </div>
              <div class="text-center mt-2">
                <h3 class="text-2xl text-slate-700 font-bold leading-normal mb-1">
                  {currentUser.name}
                </h3>
              </div>

              <div class="mt-6 py-6 border-t border-slate-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full px-4">
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      //label="Name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      disabled
                      value={currentUser.name}
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      //  label="Email"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={currentUser.email}
                      disabled
                    />


                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      //  label="Email"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={currentUser.phone_number}
                      disabled
                    />


                    <div class="mt-6 py-6 border-t border-slate-200 text-center">

                      <TextField
                        label="Current Password"
                        variant="outlined"
                        type="password"
                        error={error.some(error=>error.path === "current_password")}
                        helperText={error.some(error=>error.path === "current_password") && error.map((error)=>{if(error.path === "current_password"){return error.msg}})}
                        style={{ width: "100%", marginBottom: "15px" }}
                        value={currentPassword}
                        onChange={(e) => {
                          if (
                            e.target.value === "" ||
                            newPassword === "" ||
                            confirmPassword === ""
                          ) {
                            setDisabledBtn(true);
                          } else {
                            setDisabledBtn(false);
                          }
                          setCurrentPassword(e.target.value);
                          setDisabledCancelBtn(false);
                        }}
                      />

                      < TextField
                        label="New Password"
                        variant="outlined"
                        type="password"
                        error={error.some(error=>error.path === "password")}
                        helperText={error.some(error=>error.path === "password") && error.map((error)=>{if(error.path === "password"){return error.msg}})}
                        style={{ width: "100%", marginBottom: "15px" }}
                        value={newPassword}
                        onChange={(e) => {
                          if (
                            e.target.value === "" ||
                            currentPassword === "" ||
                            confirmPassword === ""
                          ) {
                            setDisabledBtn(true);
                          } else {
                            setDisabledBtn(false);
                          }
                          setNewPassword(e.target.value);
                          setDisabledCancelBtn(false);
                        }}
                      />
                      < TextField
                        label="Confirm Password"
                        variant="outlined"
                        type="password"
                        error={error.some(error=>error.path === "password_confirmation")}
                        helperText={error.some(error=>error.path === "password_confirmation") && error.map((error)=>{if(error.path === "password_confirmation"){return error.msg}})}
                        style={{ width: "100%", marginBottom: "15px" }}
                        value={confirmPassword}
                        onChange={(e) => {
                          if (
                            e.target.value === "" ||
                            currentPassword === "" ||
                            newPassword === ""
                          ) {
                            setDisabledBtn(true);
                          } else {
                            setDisabledBtn(false);
                          }
                          setConfirmPassword(e.target.value);
                          setDisabledCancelBtn(false);
                        }}
                      />

                   


                      <Stack direction="row">
                        <button
                          type="submit"
                          className="flex  justify-center rounded-md  m-[10px] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-[#212e79] disabled:bg-[#e8e4e4] "

                          disabled={disabledBtn}
                          onClick={() => {
                            setPassword();
                          }}
                        >
                          Reset Password
                        </button>
                        <button
                          type="submit"
                          className="flex justify-center rounded-md  m-[10px] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-[#d62525] disabled:bg-[#e8e4e4] "

                          disabled={disableCancelBtn}
                          onClick={() => {
                            cancel();
                          }}
                        >
                          Cancel
                        </button>
                      </Stack>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
