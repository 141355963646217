
import "./App.css";
import { Route, Routes } from "react-router-dom";
import QualityAssurance from "./Pages/Admin/QualityAssurance";
import TaskComponent from "./Pages/Admin/Task";
import Project from "./Pages/Admin/Project";
import LoginPage from "./Pages/Login";
import TextAnnotation from "./Components/TextAnnotation";
import VideoAnnotation from "./Components/VideoAnnotation";
import ImageAnnotation from "./Components/ImageAnnotation";
import AnnotatorTasks from "./Pages/Annotator/MyTasks";
import AnnotatorProject from "./Pages/Annotator/Project";
import Profile from "./Components/Profile";
import Annotator from "./Pages/Admin/Annotator";
import QualityAssuranceTasks from "./Pages/QualityAssurance/MyTasks";
import SetPassword from "./Pages/SetPassword";
import QualityAssuranceProject from "./Pages/QualityAssurance/Projects";
import ProjectPage from "./Pages/ProjectPage";
import Support from "./Pages/SupportPage";
import TicketDetails from "./Components/TicketDetails";
import Home from "./Pages/SorteraAdmin/Home";
import UserTasks from "./Pages/Admin/UserTasks";

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<LoginPage />} />
      <Route path="/admin/" exact element={<Project />} />
      <Route
        path="/admin/quality-assurance"
        exact
        element={<QualityAssurance />}
      />
      <Route path="/admin/task" exact element={<TaskComponent />} />
      <Route path="/task/image/:id/:id" exact element={<ImageAnnotation />} />
      <Route path="/project/:id" exact element={<ProjectPage />} />
      <Route path="/login" exact element={<LoginPage />} />
      <Route path="/set-password" exact element={<SetPassword />} />
      <Route path="/task/text/:id/:id" exact element={<TextAnnotation />} />
      <Route path="/video" exact element={<VideoAnnotation />} />
      <Route path="admin/annotator" exact element={<Annotator />} />
      <Route path="/annotator/" exact element={<AnnotatorTasks />} />
      <Route path="/annotator/project" exact element={<AnnotatorProject />} />
      <Route path="/:id/profile" exact element={<Profile />} />
      <Route
        path="/quality-assurance"
        exact
        element={<QualityAssuranceTasks />}
      />
      <Route
        path="/quality-assurance/project"
        exact
        element={<QualityAssuranceProject />}
      />
      <Route path="/support" exact element={<Support />} />
      <Route path="/support/ticket/:id" exact element={<TicketDetails />} />
      <Route path="/sortera-admin/" exact element={<Home />} />
      <Route path="/admin/:id/:id" exact element={<UserTasks/>} />
    </Routes>
  );
}

export default App;
