import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { TokenAnnotator } from "react-text-annotate";
import toast, { Toaster } from "react-hot-toast";
import qualityAssurance from "../api/qualityAssurance";
import user from "../api/user";
import AnnotationDash from "./AnnotationDash";
import SideBar from "./SideBar";
import { useNavigate } from "react-router-dom";

// this component takes two props
// 1. Text
// 2. Classifications

function TextAnnotation() {
  const [text, setText] = useState("");
  const [annotations, setAnnotations] = useState([]);
  const [taskInfo, setTaskInfo] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [currentClassification, setCurrentClassification] = useState([]);
  const [currentClassificationTag, setCurrentClassificationTag] = useState("");
  const [currentClassificationColor, setCurrentClassificationColor] =
    useState("");

  const navigate = useNavigate();
  const getText = (text) => {
    return text;
  };
  const handleChange = (value) => {
    setAnnotations(value);
  };
  const handleClassificationChange = (e) => {
    const selectedClassification = classifications.filter((classification) => {
      return classification.value == e.target.value;
    });

    setCurrentClassification(selectedClassification[0].value);
    setCurrentClassificationColor(selectedClassification[0].color);
    setCurrentClassificationTag(selectedClassification[0].title);
  };

  const handelReset = async () => {
    if (taskInfo.status === 0 || taskInfo.status === 3) {
      setAnnotations((prev) => []);
    } else {
      const data = {
        task_id: taskInfo.id,
      };

      await qualityAssurance
        .rejectTask(data.task_id)
        .then((response) => {
          toast.success("Task rejected successfully", {
            style: { padding: "15px" },
          });
          setTimeout(() => {
            navigate(-1);
          }, 500);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location = "/login"
          }

          toast.error("Error Please Try again", {
            style: { padding: "15px" },
          });
        });
    }
  };

  const handelSubmit = async () => {
    // here we call to the backend to send the new take and return to the home page

    if (taskInfo.status === 0 || taskInfo.status === 3) {
      let data = {
        taskID: taskInfo.id,
        annotationData: JSON.stringify(annotations),
        annotatorType: JSON.parse(localStorage.getItem("user")).type,
        annotatorId: JSON.parse(localStorage.getItem("user")).id,
        owner_id: taskInfo.project_ownerid,
      };

      user
        .createAnnotation(data)
        .then((response) => {
          toast.success("Annotation submitted successfully", {
            style: { padding: "15px" },
          });
          setTimeout(() => {
            navigate(-1);
          }, 500);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.clear();
            window.location = "/login"
          }

          toast.error("Error, please try again", {
            style: { padding: "15px" },
          });
        });
    } else {
      const data = {
        task_id: taskInfo.id,
        quality_assurance_id: JSON.parse(localStorage.getItem("user")).id,
      };
      await qualityAssurance
        .approveTask(data)
        .then((response) => {
          
          toast.success("the task approved successfully", {
            style: { padding: "15px" },
          });

          setTimeout(() => {
            navigate(-1);
          }, 500);
        })
        .catch((err) => {


          if (err.response.status === 401) {
            localStorage.clear();
            window.location = "/login"
          }

          toast.error("Error, please try again", {
            style: { padding: "15px" },
          });
        });
    }
  };

  useEffect(() => {
    
    const getData = async () => {
      const taskID = window.location.href.split("/")[6];
      user
        .getTask(taskID)
        .then((response) => {

          
          let data = response.data.taskInfo;

          const projectClassifications = JSON.parse(data.classifications);
          // set the text content of the task
          setText(response.data.textData);

          let classifications = [];
          projectClassifications.map((classification) => {
            const newClassification = {
              id: classification.id,
              title: classification.title,
              value: classification.title,
              color: classification.color,
            };
            classifications.push(newClassification);
          });
          setClassifications(classifications);
          setCurrentClassification(projectClassifications[0].title);
          setTaskInfo(data);

          const annotations =
            data.status === 0 ? [] : JSON.parse(data.annotation_data);
          setAnnotations(annotations);
          setCurrentClassificationColor(projectClassifications[0].color);
          setCurrentClassificationTag(projectClassifications[0].title);
        })
        .catch((err) => {
          toast.error("Error Please Try again", {
            style: { padding: "15px" },
          });
        });
    };
    getData();
  }, []);

  return (
    <div className="App">
      <SideBar currentDestination="My Tasks" list={[]} />
      <Toaster position="bottom-left" reverseOrder={false} />
      <div className="md:p-1 sm:ml-64">
        <div className="p-1 md:mt-3">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item md={9}>
              <div
                style={{
                  boxShadow: "0 2px 4px rgba(0,0,0,.1)",
                  margin: 10,
                  padding: 16,
                  boxShadow: " 1.5px 1.5px 3.5px #000000",
                  pointerEvents:
                    taskInfo.status === 0 || taskInfo.status === 3
                      ? "auto"
                      : "none",
                }}
              >
                <TokenAnnotator
                  content={getText(text)}
                  disabled={true}
                  style={{
                    fontFamily: "IBM Plex Sans",
                    lineHeight: 1.5,
                  }}
                  tokens={text.split(" ")}
                  value={annotations}
                  onChange={handleChange}
                  getSpan={(span) => ({
                    ...span,
                    tag: currentClassificationTag,
                    color: currentClassificationColor,
                  })}
                />
              </div>
            </Grid>
            <Grid item md={3}>
              <AnnotationDash
                taskName="Annotate Text"
                taskDescription="Annotate each text according to this markdown Classification."
                classification={currentClassification}
                classifications={classifications}
                handelReset={handelReset}
                handleClassificationChange={handleClassificationChange}
                handelSubmit={handelSubmit}
                taskAnnotation="Text Annotation"
                taskStatus={taskInfo.status}
                userType={JSON.parse(localStorage.getItem("user")).type}
                task={taskInfo}
                annotations={annotations}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default TextAnnotation;
