import React, { useEffect, useState } from "react";
import qualityAssurance from "../../api/qualityAssurance";
import user from "../../api/user";
import AnnotatorTasksTable from "../../Components/AnnotatorTaskTable";
import SideBar from "../../Components/SideBar";
import TasksTable from "../../Components/TasksTable";
import { qualityAssuranceFeatures } from "../../Data/usersFeatures";
import toast, { Toaster } from "react-hot-toast";
function QualityAssuranceTasks(props) {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const getData = async () => {
      let currentUser = JSON.parse(localStorage.getItem("user"));
      const owner_id = currentUser.owner_id;

      await qualityAssurance
        .getTasks(owner_id)
        .then((tasks) => {
          setTasks(tasks.data.tasks);
         
        })
        .catch((error) => {
          if (error.code === "ERR_NETWORK") {

            toast.error("Error! please try again", {
              style: { padding: "15px" },
            });

          } else if (error.response.status === 401) {
            localStorage.clear();
            window.location = "/login"
          } else {
            toast.error("Error! please try again", {
              style: { padding: "15px" },
            });
          }
        });
    };
    getData();
  }, []);
  return (
    <div className="App" style={{ minHeight: "100vh" }}>
      <Toaster position="bottom-left" reverseOrder={false} />
      <SideBar currentDestination="My Tasks" list={qualityAssuranceFeatures} />
      <div className="md:p-4 sm:ml-64">
        <div className="p-2 md:mt-3">
          <h4>Welcome, {JSON.parse(localStorage.getItem("user")).name} </h4>
          <h4>My Tasks </h4>
          <TasksTable
            data={tasks}
            type={1}
            featuresList={qualityAssuranceFeatures}
          />
        </div>
      </div>
    </div>
  );
}
export default QualityAssuranceTasks;
