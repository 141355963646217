import { DialogContent, TextField, Button, DialogActions } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from "@mui/lab";
import superAdmin from '../api/superAdmin';


function CreateAdmin(props) {

    const [name, setName] = useState(props.name == null ? "" : props.name);
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [numberOfProject, setNumberOfProject] = useState("");
    const [numberOfTasks, setNumberOfTasks] = useState("");
    const [numberOfAnnotators, setNumberOfAnnotators] = useState("");
    const [numberOfQualityAssurance, setNumberOfQualityAssurance] = useState("");
    const [disabledBtn, setDisabledBtn] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState([]);



    const handleClose = () => {
        props.setOpenDialog(false);
    }


    const updateAdmin = async () => {

        const data = {
            "name": name,
            "email": email,
            "phone_number": phoneNumber,
            "project_limit": numberOfProject,
            "task_limit": numberOfTasks,
            "annotator_limit": numberOfAnnotators,
            'quality_assurance_limit': numberOfQualityAssurance,
            "email_changed": email === props.selectedAdmin.email ? false : true,
        }
        setLoading(true);
        await superAdmin.updateAdmin(data, props.selectedAdmin.id)
            .then((response) => {

                let admins = props.admins.filter((item) => {
                    if (item.id === props.selectedAdmin.id) {
                        item.name = name;
                        item.email = email;
                        item.phone_number = phoneNumber;
                        item.project_limit = numberOfProject;
                        item.task_limit = numberOfTasks;
                        item.annotator_limit = numberOfAnnotators;
                        item.quality_assurance_limit = numberOfQualityAssurance;

                    }
                    return item;
                });
                props.setOpenDialog(false);
                setLoading(false);
                props.toast.success("Admin Update Successfully", {
                    style: { padding: "15px" },
                });
            })
            .catch((error) => {

                if (error.code == "ERR_NETWORK") {
                    props.toast.error("Error. please try again!", {
                        style: { padding: "15px" },
                    });
                    setLoading(false);
                } else if (error.code == "ERR_BAD_REQUEST") {

                    if (error.response.status === 422) {
                        setError(error.response.data.validationRequestResult.errors);
                    } else if (error.response.data.ref_id === 10002) {
                        setError([{ msg: error.response.data.message, path: "email" }]);
                        setLoading(false);
                    } else if (error.response.status === 400) {
                        props.toast.error(`there is an error please try again`, {
                            style: { padding: "15px" },
                        });
                    }
                }
                else {
                    props.toast.error("Error. please try again!", {
                        style: { padding: "15px" },
                    });
                    setLoading(false);
                }

            })
    }
    const createNewAdmin = async () => {

        const data = {
            "name": name,
            "email": email,
            "phone_number": phoneNumber,
            "project_limit": numberOfProject,
            "task_limit": numberOfTasks,
            "annotator_limit": numberOfAnnotators,
            'quality_assurance_limit': numberOfQualityAssurance,
        }
        setLoading(true);
        await superAdmin.createAdmin(data)
            .then((response) => {
                props.setAdmins(admins => [...admins, response.data.user]);
                props.setOpenDialog(false);
                setLoading(false);
                props.toast.success("Admin Created Successfully", {
                    style: { padding: "15px" },
                });
            })
            .catch((error) => {

              if (error.code == "ERR_NETWORK") {
                    props.toast.error("Error. please try again!", {
                        style: { padding: "15px" },
                    });
                    setLoading(false);
                } else if (error.code == "ERR_BAD_REQUEST") {

                    if (error.response.status === 422) {
                        setError(error.response.data.validationRequestResult.errors);
                    } else if (error.response.data.ref_id === 10002) {
                        setError([{ msg: error.response.data.message, path: "email" }]);
                        setLoading(false);
                    } else if (error.response.status === 400) {
                        props.toast.error(`there is an error please try again`, {
                            style: { padding: "15px" },
                        });
                    }
                }
                else {
                    props.toast.error("Error. please try again!", {
                        style: { padding: "15px" },
                    });
                    setLoading(false);
                }

            })
    }

    useEffect(() => {

        const getData = () => {


            if (props.selectedAdmin !== null) {
                setName(props.selectedAdmin.name);
                setEmail(props.selectedAdmin.email);
                setPhoneNumber(props.selectedAdmin.phone_number);
                setNumberOfProject(props.selectedAdmin.project_limit);
                setNumberOfQualityAssurance(props.selectedAdmin.quality_assurance_limit);
                setNumberOfAnnotators(props.selectedAdmin.annotator_limit);
                setNumberOfTasks(props.selectedAdmin.task_limit);
            }

        }
        props.selectedAdmin !== null && getData();


    }, []);
    return (
        <div>
            <div className="p-4 border-b rounded-t">
                <p className="text-4xl font-thin text-gray-900 ">Add New Admin</p>
                <p className="text-justify text-gray-500 ">
                    Please fill in all the fields..
                </p>
            </div>

            <DialogContent>

                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    error={error.some(error => error.path === "name")}
                    helperText={error.some(error => error.path === "name") && error.map((error) => { if (error.path === "name") { return error.msg } })}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={name}
                    onChange={(e) => {
                        if (e.target.value === "" || email === "" || phoneNumber === "" || numberOfAnnotators === 0 || numberOfProject === 0 || numberOfTasks === 0 || numberOfQualityAssurance === 0) {
                            setDisabledBtn(true);
                        } else {
                            setDisabledBtn(false);
                        }
                        setName(e.target.value)
                    }}
                />

                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={email}
                    error={error.some(error => error.path === "email")}
                    helperText={error.some(error => error.path === "email") && error.map((error) => { if (error.path === "email") { return error.msg } })}
                    onChange={(e) => {

                        if (e.target.value === "" || name === "" || phoneNumber === "" || numberOfAnnotators === "" || numberOfProject === "" || numberOfTasks === "" || numberOfQualityAssurance === "") {
                            setDisabledBtn(true);
                        } else {
                            setDisabledBtn(false);
                        }
                        setEmail(e.target.value)
                    }}
                />

                <TextField
                    autoFocus
                    margin="dense"
                    id="phoneNumber"
                    label="Phone Number"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={phoneNumber}
                    error={error.some(error => error.path === "phone_number")}
                    helperText={error.some(error => error.path === "phone_number") && error.map((error) => { if (error.path === "phone_number") { return error.msg } })}
                    onChange={(e) => {

                        if (e.target.value === "" || name === "" || email === "" || numberOfAnnotators === "" || numberOfProject === "" || numberOfTasks === "" || numberOfQualityAssurance === "") {
                            setDisabledBtn(true);
                        } else {
                            setDisabledBtn(false);
                        }
                        setPhoneNumber(e.target.value)
                    }}
                />

                <TextField
                    autoFocus
                    margin="dense"
                    label="Projects limits"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={numberOfProject}
                    error={error.some(error => error.path === "project_limit")}
                    helperText={error.some(error => error.path === "project_limit") && error.map((error) => { if (error.path === "project_limit") { return error.msg } })}
                    onChange={(e) => {

                        if (e.target.value === "" || name === "" || email === "" || phoneNumber === 0 || numberOfAnnotators === "" || numberOfTasks === "" || numberOfQualityAssurance === "") {
                            setDisabledBtn(true);
                        } else {
                            setDisabledBtn(false);
                        }
                        setNumberOfProject(e.target.value)
                    }}
                />

                <TextField
                    autoFocus
                    margin="dense"
                    label="Quality assurance limits"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={numberOfQualityAssurance}
                    error={error.some(error => error.path === "quality_assurance_limit")}
                    helperText={error.some(error => error.path === "quality_assurance_limit") && error.map((error) => { if (error.path === "quality_assurance_limit") { return error.msg } })}
                    onChange={(e) => {

                        if (e.target.value === "" || name === "" || email === "" || phoneNumber === "" || numberOfAnnotators === "" || numberOfTasks === "" || numberOfProject === "") {
                            setDisabledBtn(true);
                        } else {
                            setDisabledBtn(false);
                        }
                        setNumberOfQualityAssurance(e.target.value)
                    }}
                />

                <TextField
                    autoFocus
                    margin="dense"
                    label="Annotators limits"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={numberOfAnnotators}
                    error={error.some(error => error.path === "annotator_limit")}
                    helperText={error.some(error => error.path === "annotator_limit") && error.map((error) => { if (error.path === "annotator_limit") { return error.msg } })}
                    onChange={(e) => {

                        if (e.target.value === "" || name === "" || email === "" || phoneNumber === "" || numberOfQualityAssurance === "" || numberOfTasks === "" || numberOfProject === "") {
                            setDisabledBtn(true);
                        } else {
                            setDisabledBtn(false);
                        }
                        setNumberOfAnnotators(e.target.value)
                    }}
                />

                <TextField
                    autoFocus
                    margin="dense"
                    label="Tasks limits"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={numberOfTasks}
                    error={error.some(error => error.path === "task_limit")}
                    helperText={error.some(error => error.path === "task_limit") && error.map((error) => { if (error.path === "task_limit") { return error.msg } })}
                    hint="outlined"
                    onChange={(e) => {

                        if (e.target.value === "" || name === "" || email === "" || phoneNumber === "" || numberOfQualityAssurance === "" || numberOfAnnotators === "" || numberOfProject === "") {
                            setDisabledBtn(true);
                        } else {
                            setDisabledBtn(false);
                        }
                        setNumberOfTasks(e.target.value)
                    }}
                />


            </DialogContent>

            <DialogActions>
                <Button
                    style={{ margin: "10px" }}
                    variant="contained"
                    color="error"
                    onClick={handleClose}>
                    Cancel
                </Button>

                {props.selectedAdmin !== null ? (
                    <LoadingButton
                        style={{ margin: "10px", borderRadius: "6px", padding: '6px', backgroundColor: disabledBtn ? "#e0e0e0" : "#212e79", color: disabledBtn ? "#a6a6a6" : "white" }}
                        className="flex  justify-center rounded-md  m-[10px] px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm  "
                        size="medium"
                        loading={loading}
                        variant="contained"
                        onClick={updateAdmin}
                        disabled={disabledBtn}
                    >
                        Update
                    </LoadingButton>

                ) : (
                    <LoadingButton
                        style={{ margin: "10px", borderRadius: "6px", padding: '6px', backgroundColor: disabledBtn ? "#e0e0e0" : "#212e79", color: disabledBtn ? "#a6a6a6" : "white" }}
                        className="flex  justify-center rounded-md  m-[10px] px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm  "
                        size="medium"
                        loading={loading}
                        variant="contained"
                        onClick={createNewAdmin}
                        disabled={disabledBtn}
                    >
                        Create
                    </LoadingButton>
                )}

            </DialogActions>

        </div>
    )
}

export default CreateAdmin;