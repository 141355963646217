import React, { useState } from "react";
import sorterLogo from "../assets/images/sorteraLogo.png";
import resetIcon from "../assets/icons/reset-password.png";
import successIcon from "../assets/icons/success.png";
import { Grid, TextField } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import auth from "../api/auth";
import { useSelector } from "react-redux";
function SetPassword(props) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [passwordSet, setPasswordSet] = useState(false);
  // const { user } = useSelector((state) => state.user);

  const setPassword = async () => {
    const data = {
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
      token: localStorage.getItem("reset_token"),
    };
  
    await auth
      .setPassword(data)
      .then((data) => {
        setPasswordSet(true);
      })
      .catch((error) => {
        
        if(error.code === "ERR_BAD_REQUEST"){
          if(error.response.status === 422){
            setError(error.response.data.validationRequestResult.errors);
          }else if(error.response.status ===400){
            if(error.response.data.code === 4001){
              setError([{ msg:error.response.data.message,path:"current_password"}]) ;
            }else if(error.response.data.code === 4002){
              
              setError([{ msg:error.response.data.message,path:"password_confirmation"}]) ;
            } else {
              
              toast.error("Error Please Try Again", {
                style: { padding: "15px" },
              });
              setError([]);
            }
          } else if(error.response.status === 401){
            toast.error("Time is up you need to login again", {
              style: { padding: "15px" },
            });
            setError([]);
          }
          
        }
       // setError(error.response.data.message);
      });
    // setPasswordSet(true);
  };
  return (
    <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <Toaster position="bottom-left" reverseOrder={false} />
      <div class="text-center">
        <img
          className="mx-auto h-10 w-auto"
          src={passwordSet ? successIcon : resetIcon}
          alt="Sortera Logo"
          style={{ width: "150px", height: "150px" }}
        />
        <h4 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">
          {passwordSet ? "Password Reset" : "Set a new Password"}
        </h4>
        <p class="mt-6 text-base leading-7 text-gray-600">
          {passwordSet
            ? "Your password has been successfully reset."
            : "Your new password must be different from previous used password."}
        </p>
        {passwordSet && (
          <p class="mt-1 text-base leading-7 text-gray-600">
            Click Continue to login again
          </p>
        )}

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {passwordSet ? (
            <button
              type="submit"
              className="flex w-full justify-center rounded-md  m-[10px] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-[#212e79]  "
              onClick={() => {
                window.location.href = "/login";
              }}
            >
              Continue
            </button>
          ) : (
            <>
              <TextField
                label="Current Password"
                variant="outlined"
                type="password"
                error={error.some(error=>error.path === "current_password")}
                helperText={error.some(error=>error.path === "current_password") && error.map((error)=>{if(error.path === "current_password"){return error.msg}})}
                style={{ width: "100%", margin: "10px" }}
                value={currentPassword}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    newPassword === "" ||
                    confirmPassword === ""
                  ) {
                    setDisabledBtn(true);
                  } else {
                    setDisabledBtn(false);
                  }
                  setCurrentPassword(e.target.value);
                }}
              />

              <TextField
                label="New Password"
                variant="outlined"
                type="password"
                error={error.some(error=>error.path === "password")}
                helperText={error.some(error=>error.path === "password") && error.map((error)=>{if(error.path === "password"){return error.msg}})}
                style={{ width: "100%", margin: "10px" }}
                value={newPassword}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    currentPassword === "" ||
                    confirmPassword === ""
                  ) {
                    setDisabledBtn(true);
                  } else {
                    setDisabledBtn(false);
                  }
                  setNewPassword(e.target.value);
                }}
              />
              <TextField
                label="Confirm Password"
                variant="outlined"
                type="password"
                error={error.some(error=>error.path === "password_confirmation")}
                helperText={error.some(error=>error.path === "password_confirmation") && error.map((error)=>{if(error.path === "password_confirmation"){return error.msg}})}
                style={{ width: "100%", margin: "10px" }}
                value={confirmPassword}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    currentPassword === "" ||
                    newPassword === ""
                  ) {
                    setDisabledBtn(true);
                  } else {
                    setDisabledBtn(false);
                  }
                  setConfirmPassword(e.target.value);
                }}
              />

              {/* <p>{error}</p> */}
              <button
                type="submit"
                className="flex w-full justify-center rounded-md  m-[10px] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-[#212e79] disabled:bg-[#e8e4e4] "
                disabled={disabledBtn}
                onClick={() => {
                  setPassword();
                }}
              >
                Reset Password
              </button>
            </>
          )}
        </Grid>
      </div>
    </main>
  );
}
export default SetPassword;
