import {
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  InputLabel,
  Typography,
  OutlinedInput,
  Chip,
  Box,
  Grid,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UploadFile from "./UploadFile";
import toast, { Toaster } from "react-hot-toast";
import admin from "../api/admin";
import { LoadingButton } from "@mui/lab";

function CreateTask(props) {
  //data
  const [type, setType] = useState("OneTask");
  const [taskDescription, setTaskDescription] = useState("");
  const [selectProject, setSelectedProject] = useState("");
  const [projectFiles, setProjectFiles] = useState([]);
  const [selectedAnnotatorType, setSelectedAnnotatorType] = useState("random");
  const [selectedMultipleAnnotators, setSelectedMultipleAnnotators] = useState(
    []
  );
  const [selectedOneAnnotator, setSelectedOneAnnotator] = useState("");
  const [annotatorsList, setAnnotatorsList] = useState([]);
  const [selectedAnnotators, setSelectedAnnotators] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);

  // functions
  const handleChangeProject = (e) => {
    setSelectedProject(e.target.value);
    setProjectFiles([]);
  };

  const handleClose = () => {
    setTaskDescription("");
    setSelectedProject("");
    setSelectedAnnotatorType("random");
    setSelectedAnnotators([]);
    setSelectedOneAnnotator("");
    setProjectFiles([]);
    setLoading(false);
    setDisabledBtn(true);
    setSelectedMultipleAnnotators([]);
    props.setOpenDialog(false);
  };

  const handleChange = (event) => {
    if (type === "MultipleTasks") {
      setSelectedMultipleAnnotators(event.target.value);
      setSelectedAnnotators(event.target.value);
    } else {
      setSelectedOneAnnotator(event.target.value);
      setSelectedAnnotators([event.target.value]);
    }

    if (projectFiles.length <= 0 || taskDescription === "") {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }
  };

  const createTask = async () => {
    setLoading(true);
    let data = new FormData();
    data.append("description", taskDescription);
    data.append("project_id", selectProject.id);
    data.append("project_owner_id", selectProject.owner_id);
    data.append("classification", selectProject.classifications);
    data.append("type", selectProject.type);
    data.append("name", selectProject.name);

    if (selectedAnnotatorType === "custom") {
      data.append("project_annotators", JSON.stringify(selectedAnnotators));
    } else {
      data.append("project_annotators", null);
    }

    for (let i = 0; i < projectFiles.length; i++) {
      data.append("images", projectFiles[i]);
    }

    admin
      .createNewTask(data)
      .then((response) => {
        props.toast.success(`tasks created successfully`, {
          style: { padding: "15px" },
        });
        setTaskDescription("");
        setSelectedProject("");
        setSelectedAnnotatorType("random");
        setSelectedAnnotators([]);
        setSelectedOneAnnotator("");
        setProjectFiles([]);
        setLoading(false);
        setDisabledBtn(true);
        setSelectedMultipleAnnotators([]);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {

          if (error.response.status === 422) {

            setError(error.response.data.validationRequestResult.errors);
          } else if (error.response.status === 400) {
            if (error.response.data.code === 40003) {
              props.toast.error(error.response.data.message, {
                style: { padding: "15px" },
              });
            } else {
              props.toast.error(`there is an error please try again`, {
                style: { padding: "15px" },
              });
            }
          }
        } else {

          props.toast.error(`there is an error please try again`, {
            style: { padding: "15px" },
          });
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    const getAnnotatorsData = async () => {
      const adminId = JSON.parse(localStorage.getItem("user"))?.id;

      admin
        .getMyAnnotators(adminId)
        .then((response) => {
          setAnnotatorsList(response.data.annotators);
        })
        .catch((error) => {
          toast.error(`there is an error please try again`, {
            style: { padding: "15px" },
          });
        });
    };

    getAnnotatorsData();
  }, []);
  return (
    <Dialog open={props.openDialog} onClose={props.handleClose}>
      <div>
        <Toaster position="bottom-left" reverseOrder={false} />
      </div>

      <div className="p-4 border-b rounded-t">
        <p className="text-4xl font-thin text-gray-900 ">Add New Task</p>
        <p className="text-justify text-gray-500 ">
          Please fill in all the fields To Add New Task.
        </p>
      </div>

      <DialogContent>
        <FormControl>
          <RadioGroup row name="row-radio-buttons-group">
            <FormControlLabel
              value="OneTask"
              control={
                <Radio
                  value="OneTask"
                  checked={type == "OneTask"}
                  onChange={(e) => {
                    setType(e.target.value);
                    if (selectedAnnotatorType === "custom") {
                      setDisabledBtn(true);
                    }
                  }}
                />
              }
              label="One Task"
            />
            <FormControlLabel
              value="MultipleTasks"
              control={
                <Radio
                  value="MultipleTasks"
                  checked={type == "MultipleTasks"}
                  onChange={(e) => {
                    setType(e.target.value);
                    if (
                      projectFiles.length >= 0 ||
                      selectedAnnotators.length >= 0
                    ) {
                      setDisabledBtn(false);
                    }
                  }}
                />
              }
              label="Multiple Tasks"
            />
          </RadioGroup>
        </FormControl>

        {/* task description  */}
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Task Description"
          type="text"
          fullWidth
          error={error.some(error => error.path === "description")}
          helperText={error.some(error => error.path === "description") && error.map((error) => { if (error.path === "description") { return error.msg } })}
          variant="outlined"
          value={taskDescription}
          onChange={(e) => {
            setTaskDescription(e.target.value);
            if (
              e.target.value != "" &&
              selectProject != "" &&
              projectFiles.length > 0
            ) {
              setDisabledBtn(false);
            } else {
              setDisabledBtn(true);
            }
          }}
        />

        {/* Project  */}
        <FormControl sx={{ width: 300, marginTop: "10px" }}>
          <InputLabel id="demo-multiple-name-label">Project</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectProject}
            label="Project"

            onChange={handleChangeProject}
            defaultValue={30}
          >
            {props.project.map((project) => (
              <MenuItem value={project}>{project.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectProject && (
          <>
            <UploadFile
              enableMultiple={type === "MultipleTasks"}
              setProjectFiles={setProjectFiles}
              toast={toast}
              type={selectProject.type}
              setDisabledBtn={setDisabledBtn}
            />
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <FormControl>
                <Typography style={{ padding: "10px" }}>Annotators:</Typography>
                <RadioGroup row name="row-radio-buttons-group">
                  <FormControlLabel
                    value="random"
                    control={
                      <Radio
                        value="random"
                        checked={selectedAnnotatorType == "random"}
                        onChange={(e) => {
                          setSelectedAnnotatorType(e.target.value);

                          if (
                            projectFiles.length <= 0 ||
                            taskDescription === ""
                          ) {
                            setDisabledBtn(true);
                          } else {
                            setDisabledBtn(false);
                          }
                        }}
                      />
                    }
                    label="Random"
                  />
                  <FormControlLabel
                    value="custom"
                    control={
                      <Radio
                        value="custom"
                        checked={selectedAnnotatorType == "custom"}
                        onChange={(e) => {
                          setSelectedAnnotatorType(e.target.value);
                          if (
                            projectFiles.length <= 0 ||
                            taskDescription === ""
                          ) {
                            setDisabledBtn(true);
                          } else if (selectedAnnotators.length >= 0) {
                            setDisabledBtn(true);
                          } else {
                            setDisabledBtn(false);
                          }
                        }}
                      />
                    }
                    label="Custom"
                  />
                </RadioGroup>
              </FormControl>

              {selectedAnnotatorType === "custom" && (
                <FormControl sx={{ width: 300 }}>
                  <InputLabel id="demo-multiple-name-label">
                    Annotators
                  </InputLabel>
                  {type === "MultipleTasks" ? (
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={selectedMultipleAnnotators}
                      onChange={handleChange}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Annotator "
                        />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => (
                            <Chip key={value.id} label={value.name} />
                          ))}
                        </Box>
                      )}
                    >
                      {annotatorsList.map((annotator) => (
                        <MenuItem key={annotator.id} value={annotator}>
                          {annotator.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedOneAnnotator}
                      label="Annotators"
                      onChange={handleChange}
                    >
                      {annotatorsList.map((annotator) => (
                        <MenuItem value={annotator} key={annotator.id}>
                          {annotator.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              )}
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          style={{ margin: "10px" }}
          variant="contained"
          color="error" 
          onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          style={{ margin: "10px", borderRadius: "6px", padding: '6px', backgroundColor: disabledBtn ? "#e0e0e0" : "#212e79", color: disabledBtn ? "#a6a6a6" : "white" }}
          className="flex  justify-center rounded-md  m-[10px] px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm  "
          size="medium"
          loading={loading}
          variant="contained"
          onClick={createTask}
          disabled={disabledBtn}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default CreateTask;
