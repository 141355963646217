import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect } from "react";
import AnnotationList from "./AnnotationList";
// this component take some props
// 1. taskTitle
//2. takeDescription
//3. classification
//4. classifications ==>array continues all the classifications for select
//5. classificationColor ===> for Text Annotation (every classification have specific color)
// 6. handleClassificationChange() fot handel the select
// 7. disabledBtn for disabled button
//8. handelReset()  for rest the annotation editor
//9 handelSubmit() for submit the annotation
//10. taskAnnotation take the Type of the annotation

function AnnotationDash(props) {

  useEffect(() => { });
  return (
    <div
      style={{
        boxShadow: "0 2px 4px rgba(0,0,0,.1)",
        boxShadow: " 1.5px 1.5px 3.5px #000000",
        minHeight: "100vh",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ padding: "10px" }}
      >
        <Typography variant="h5" sx={{ mb: 1.3 }} component="div">
          {props.taskAnnotation}
        </Typography>

        <Grid
          container
          direction="column"
          justifyContent="start"
          alignItems="start"
        >
          <Typography variant="h6" component="div">
            Task Description:
          </Typography>
          <Typography sx={{ mb: 1.3 }} color="text.secondary">
            {props.taskDescription}
          </Typography>
          <Typography variant="h6" component="div">
            Project:
          </Typography>
          <Typography sx={{ mb: 1.3 }} color="text.secondary">
            {props.task.project_name}
          </Typography>

          <Typography variant="h6" sx={{ mb: 1.3 }} component="div">
            Classifications:
          </Typography>
          <FormControl fullWidth>
            <InputLabel>Classifications</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.classification}
              label="Classifications"
              onChange={props.handleClassificationChange}
            >
              {props.classifications.map((classification, index) => (
                <MenuItem key={index} value={classification.value}>
                  {classification.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>


          {props.taskAnnotation === "Image Annotation" && (
            <>
              <div
                style={{
                  padding: 16,
                  width: "100%",
                }}
              >
                {props.classifications.map((classification) => (
                  <Accordion>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography>{classification.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <AnnotationList
                        type={classification.title}
                        annotations={props.annotations}
                        handelDelateAnnotation={props.handelDelateAnnotation}
                        status={props.taskStatus}
                      />
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </>
          )}

          {(props.taskStatus == 0 || props.taskStatus == 3) && (
            <ButtonGroup variant="vertical" aria-label="text button group">
              <Button
                variant="outlined"
                color="success"
                style={{ margin: "10px" }}
                onClick={props.handelSubmit}
                disabled={props.annotations.length == 0 || props.disabledBtn}
              >
                Submit
              </Button>

              <Button
                variant="outlined"
                color="error"
                style={{ margin: "10px" }}
                onClick={props.handelReset}
                disabled={props.disabledBtn}
              >
                Reset
              </Button>
            </ButtonGroup>
          )}
          {props.taskStatus === 1 && (props.userType == 1 || props.userType == 2) && (
            <ButtonGroup variant="vertical" aria-label="text button group">
              <Button
                variant="outlined"
                color="success"
                style={{ margin: "10px" }}
                onClick={props.handelSubmit}
                disabled={props.annotations.length == 0}
              >
                Approve
              </Button>

              <Button
                variant="outlined"
                color="error"
                style={{ margin: "10px" }}
                onClick={props.handelReset}
                disabled={props.disabledBtn}
              >
                Reject
              </Button>
            </ButtonGroup>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default AnnotationDash;
