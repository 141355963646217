import { Grid, Dialog, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import SideBar from "../../Components/SideBar";
import toast, { Toaster } from "react-hot-toast";
import AdminsTable from "../../Components/AdminsTable";
import superAdmin from "../../api/superAdmin";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CreateAdmin from "../../Components/CreateAdmin";

function Home() {

    const [openDialog, setOpenDialog] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [selectedAdmin, setSelectedAdmin] = useState(null);

    useEffect(() => {
        const getData = async () => {
            superAdmin.getAllAdmin()
                .then((res) => {
                    console.log(res.data.admin);
                    setAdmins(res.data.admin);
                    setLoading(false);
                }).catch((error) => {

                    if (error.code === "ERR_NETWORK") {

                        toast.error("Error! please try again", {
                            style: { padding: "15px" },
                        });

                    } else if (error.response.status === 401) {
                        localStorage.clear();
                        window.location = "/login"
                    } else {
                        toast.error("Error! please try again", {
                            style: { padding: "15px" },
                        });
                    }

                });
        }
        getData();
    }, [])
    return (
        <div className="App" style={{ minHeight: "100vh" }}>
            <SideBar currentDestination="Home" list={[]} />
            <Toaster position="bottom-left" reverseOrder={false} />
            <div className="md:p-4 sm:ml-64">
                <div className="p-2  md:mt-3">
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <button
                                onClick={() => {
                                    setSelectedAdmin(null);
                                    setOpenDialog(true)
                                }}
                                type="button"
                                className=" text-white bg-[#212e79] hover:bg-[#1d2a72] focus:ring-4 focus:outline-none focus:ring-[#30409c]  rounded-lg text-sm px-5 py-2.5  my-1 text-center inline-flex items-center"
                            >
                                Add Admin
                            </button>
                        </Grid>
                    </Grid>

                    {isLoading ? (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: "center", height: "80vh", width: "100%", alignItems: "center" }}>
                                <CircularProgress style={{ color: "#212e79" }} size="90px" />
                            </Box>
                        </>
                    ) : (
                        <>
                            <AdminsTable admins={admins} setSelectedAdmin={setSelectedAdmin} setOpenDialog={setOpenDialog} />
                        </>
                    )}
                </div>
            </div>

            <Dialog open={openDialog}>
                <CreateAdmin setOpenDialog={setOpenDialog} toast={toast} setAdmins={setAdmins} admins={admins} selectedAdmin={selectedAdmin} />
            </Dialog>
        </div>

    )
}

export default Home;