import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import user from '../api/user';
import SideBar from '../Components/SideBar';
import TasksTable from '../Components/TasksTable';
import toast, { Toaster } from "react-hot-toast";
import qualityAssurance from '../api/qualityAssurance';
import { Box } from '@mui/system';
import { CircularProgress, Grid } from '@mui/material';

function ProjectPage() {


  const location = useLocation();
  const [tasks, setTasks] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);


  const getType = (type) => {
    switch (type) {
      case 0:
        return "Image";
      case 1:
        return "Text";
      default:
        return "";
    }
  };

  useEffect(() => {
    const getTasks = async () => {
      let currentUser = JSON.parse(localStorage.getItem("user"));
      if (currentUser?.type == 1) {
        await user.getProjectTasks(location.state.project_id)
          .then((tasks) => {
            setTasks(tasks.data.tasks);
            setIsLoadingData(false);
          }).catch((error) => {
            if (error.code === "ERR_NETWORK") {
              toast.error("Error! please try again", {
                style: { padding: "15px" },
              });
            } else if (error.code === "ERR_NETWORK") {
              if (error.response.status === 401) {
                localStorage.clear();
                window.location = "/login"
              } else {
                toast.error("Error! please try again", {
                  style: { padding: "15px" },
                });
              }
            } else {
              toast.error("Error! please try again", {
                style: { padding: "15px" },
              });
            }
          })
      } else if (currentUser?.type == 2) {

        let data = {
          project_id: location.state.project_id,
          quality_assurance_id: currentUser?.id
        }
        await qualityAssurance.getProjectTasks(data)
          .then((response) => {
            setTasks(response.data.tasks);
            setIsLoadingData(false);
          }).catch((error) => {

            if (error.code === "ERR_NETWORK") {
              toast.error("Error! please try again", {
                style: { padding: "15px" },
              });
            } else if (error.code === "ERR_NETWORK") {
              if (error.response.status === 401) {
                localStorage.clear();
                window.location = "/login"
              } else {
                toast.error("Error! please try again", {
                  style: { padding: "15px" },
                });
              }
            } else {
              toast.error("Error! please try again", {
                style: { padding: "15px" },
              });
            }

          })

      } else if (currentUser?.type == 3) {

        let data = {
          project_id: location.state.project_id,
          annotator_id: currentUser?.id
        }

        await user.getAnnotatorProjectTasks(data)
          .then((response) => {
            setTasks(response.data.tasks);
            setIsLoadingData(false);
          })
          .catch((error) => {
             
            if (error.code === "ERR_NETWORK") {
              toast.error("Error! please try again", {
                style: { padding: "15px" },
              });
            } else if (error.code === "ERR_NETWORK") {
              if (error.response.status === 401) {
                localStorage.clear();
                window.location = "/login"
              } else {
                toast.error("Error! please try again", {
                  style: { padding: "15px" },
                });
              }
            } else {
              toast.error("Error! please try again", {
                style: { padding: "15px" },
              });
            }


          })
      }
    }

    if (location.state === null) {
      window.location.href = "/";
    }
    getTasks();
  }, [])
  return (
    <div className="App" style={{ minHeight: "100vh" }}>
      <Toaster position="bottom-left" reverseOrder={false} />
      <SideBar currentDestination="Project" />

      <div className="md:p-4 sm:ml-64">
        <div className="p-2 md:mt-3">

          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <div className="flex  items-center drop-shadow-md m-1">
              <span className=" text-gray-500 text-lg">Name:</span>
              <span className='p-2'>{location.state?.project?.name}</span>
            </div>


            <div className="flex  items-center drop-shadow-md m-1">
              <span className=" text-gray-500 text-lg">Type:</span>
              <span className='p-2'>{getType(location.state?.project?.type)}</span>
            </div>

            <div className="flex  items-center drop-shadow-md m-1">
              <span className=" text-gray-500 text-lg">Owner:</span>
              <span className='p-2'>{location.state?.project?.owner_name}</span>
            </div>
          </Grid>

          {isLoadingData ? (
            <Box sx={{ display: 'flex', justifyContent: "center", height: "80vh", width: "100%", alignItems: "center" }}>
              <CircularProgress style={{ color: "#212e79" }} size="90px" />
            </Box>
          ) : (
            <>
              <TasksTable data={tasks} type={1} />
            </>
          )}

        </div>
      </div>
    </div>
  )
}

export default ProjectPage;