import { createSlice } from "@reduxjs/toolkit";
export const projectSlice = createSlice({
  name: "project",
  initialState: {
    project: [],
  },
  reducers: {
    setProject: (state, projects) => {
      state.project = projects.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProject } = projectSlice.actions;

export default projectSlice.reducer;
