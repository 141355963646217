import { Box, CircularProgress, Dialog, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import admin from "../../api/admin";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SideBar from "../../Components/SideBar";
import { adminFeatures } from "../../Data/usersFeatures";
import AddManager from "./AddQualityAssurance";
import Message from "../../Components/PageMessage";
import UsersTable from "../../Components/UserTable";

function Manager() {
  const [open, setOpen] = useState(false);
  const [qualityAssurances, setQualityAssurance] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [isLoadingData,setIsLoadingData] = useState(true);

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    setCurrentUser(currentUser);
    const getData = async () => {
      let owner_id = currentUser.id;
      await admin
        .getMyQualityAssurance(owner_id)
        .then((response) => {
          setQualityAssurance(response.data.qualityAssurances);
          setIsLoadingData(false);
        })
        .catch((error) => {
          if (error.code === "ERR_NETWORK") {

            toast.error("Error! please try again", {
              style: { padding: "15px" },
            });

          } else
            if (error.response.status === 401) {
              localStorage.clear();
              window.location = "/login"
            } else {
              toast.error("Error! please try again", {
                style: { padding: "15px" },
              });
            }
        });
    };
    getData();
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handelOnClick = (value) => {
    console.log(value);
  };

  return (
    <div className="App" style={{ minHeight: "100vh" }}>
      <SideBar currentDestination="Quality Assurance" list={adminFeatures} />
      <Toaster position="bottom-left" reverseOrder={false} />
      <div className="p-4 sm:ml-64">
        <div className="p-2 md:mt-3">
          <h4> Quality Assurance</h4>
          <div className="w-full">
            <button
              onClick={handleOpen}
              type="button"
              className=" text-white bg-[#212e79] hover:bg-[#212e79]-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center float-right m-2"
            >
              New Quality Assurance
            </button>
          </div>

          {isLoadingData ? (
              <Box sx={{ display: 'flex', justifyContent: "center", height: "80vh", width: "100%", alignItems: "center" }}>
              <CircularProgress style={{ color: "#212e79" }} size="90px" />
            </Box>

            ):(
            <>
            {qualityAssurances.length > 0 ? (
            <UsersTable users={qualityAssurances} setIsLoadingData={setIsLoadingData} />
          ) : (
            <div className=" h-full w-full flex items-center justify-center">
              <Message type={3} />
            </div>
          )}
            </>)}

         
          <Dialog open={open}>
            <AddManager
              handleClose={handleClose}
              toast={toast}
              currentUser={currentUser}
            />
          </Dialog>
        </div>
      </div>
    </div>
  );
}
export default Manager;
