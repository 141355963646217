import React from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
function UploadFile(props) {
  const handleFileUpload = (e) => {
    let files = Object.entries(e.target.files);
    let size = 0;
    for (let i = 0; i < files.length; i++) {
      size += files[i][1].size;
    }
    if (size < 650000000) {
      props.setProjectFiles(e.target.files);
      props.setDisabledBtn(false);
      props.toast.success(
        `${e.target.files.length} files upload successfully`,
        {
          style: { padding: "15px" },
        }
      );
    } else {
      props.toast.error(
        "the total uploaded files should be less than the 650 MB ",
        {
          style: { padding: "15px" },
        }
      );
    }
  };

  return (
    <div style={{ marginTop: "15px" }}>
      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
        <div className="text-center">
          <UploadFileIcon color="gray" />
          <div className="mt-4  text-sm leading-6 text-gray-600  text-center flex items-center justify-center">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
            >
              <span>Upload a file</span>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                multiple={props.enableMultiple}
                onChange={handleFileUpload}
                accept={
                  props.type === 0 ? "image/png, image/jpeg" : ".txt"
                }
              />
            </label>
          </div>

          {props.enableMultiple && props.type === 0 && (
            <p className="text-xs leading-5 text-gray-600">
              the total uploaded files should be
              <br /> less than 650 MB and the accepted files is PNG, JPG
            </p>
          )}
          {!props.enableMultiple && props.type === 0 && (
            <p className="text-xs leading-5 text-gray-600">
              the accepted files is PNG, JPG
            </p>
          )}
          {props.type === 1 && (
            <p className="text-xs leading-5 text-gray-600">
              the accepted files is txt
            </p>
          )}
        </div>
      </div>
      {!props.enableMultiple && props.type === 0 && (
        <p className="pl-1 text-gray-600">Note: one Image accepted</p>
      )}
    </div>
  );
}
export default UploadFile;
