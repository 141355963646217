import React from "react";
import { TwoDimensionalVideo } from "react-annotation-tool-updated";

function VideoAnnotation() {
  return (
    <div>
      <h1>Video annotation</h1>
      <TwoDimensionalVideo
        className="react-player"
        videoWidth="350"
        url="https://www.youtube.com/watch?v=S_sFdT25eds"
      />
    </div>
  );
}

export default VideoAnnotation;
