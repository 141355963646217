import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
function AnnotationList(props) {
  const handelDelateAnnotation = (annotation) => {
    props.handelDelateAnnotation(annotation);
  };
  return (
    <>
      <List>
        {props.annotations.map((annotation) => (
          <>
            {annotation.data.text === props.type && (
              <ListItem
                secondaryAction={
                  <>
                    {(props.status === 0 || props.status === 3) && (
                      <>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          color="red"
                          onClick={() => handelDelateAnnotation(annotation)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </>
                }
              >
                <ListItemText
                  primary={annotation.data.text}
                  secondary={`X: ${annotation.geometry.x} Y: ${annotation.geometry.y}`}
                />
                <Divider />
              </ListItem>
            )}
          </>
        ))}
      </List>
    </>
  );
}
export default AnnotationList;
