import React, { useEffect, useState } from "react";
import qualityAssurance from "../../api/qualityAssurance";
import ProjectTable from "../../Components/ProjectTable";
import SideBar from "../../Components/SideBar";
import toast, { Toaster } from "react-hot-toast";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";
function AnnotatorProject(props) {

  const [projects, setProjects] = useState([]);
  const [isLoadingData,setIsLoadingData] = useState(true);
  useEffect(() => {
    const getData = async () => {
      let user_id = JSON.parse(localStorage.getItem("user"))?.owner_id;
      await qualityAssurance
        .getProjects(user_id)
        .then((projects) => {
          setProjects(projects.data.projects);
          setIsLoadingData(false);
        })
        .catch((error) => {

          if (error.code === "ERR_NETWORK") {

            toast.error("Error! please try again", {
              style: { padding: "15px" },
            });

          } else if (error.response.status === 401) {
            localStorage.clear();
            window.location = "/login"
          } else {
            toast.error("Error! please try again", {
              style: { padding: "15px" },
            });
          }
        });
    };
    getData();
  }, []);
  return (
    <div className="App" style={{ height: "100vh" }}>
      <Toaster position="bottom-left" reverseOrder={false} />
      <SideBar currentDestination="Project" />
      <div className="md:p-4 sm:ml-64">
        <div className="p-2 md:mt-3">
          <h4>Project</h4>

          {isLoadingData ? (
            <Box sx={{ display: 'flex', justifyContent: "center", height: "80vh", width: "100%", alignItems: "center" }}>
              <CircularProgress style={{ color: "#212e79" }} size="90px" />
            </Box>) : (
            <>
              <ProjectTable projects={projects} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default AnnotatorProject;
