import React, { useEffect, useState } from "react";
import qualityAssurance from "../../api/qualityAssurance";
import ProjectTable from "../../Components/ProjectTable";
import SideBar from "../../Components/SideBar";
import { qualityAssuranceFeatures } from "../../Data/usersFeatures";
import toast, { Toaster } from "react-hot-toast";
function QualityAssuranceProject(props) {
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    const getData = async () => {
      let user_id = JSON.parse(localStorage.getItem("user"))?.owner_id;
      console.log(user_id);
      await qualityAssurance
        .getProjects(user_id)
        .then((projects) => {
          console.log(projects);
          setProjects(projects.data.projects);
        })
        .catch((error) => {
          if (error.code === "ERR_NETWORK") {

            toast.error("Error! please try again", {
              style: { padding: "15px" },
            });

          } else if (error.response.status === 401) {
            localStorage.clear();
            window.location = "/login"
          } else {
            toast.error("Error! please try again", {
              style: { padding: "15px" },
            });
          }
        });
    };
    getData();
  }, []);
  return (
    <div className="App" style={{ minHeight: "100vh" }}>
      <Toaster position="bottom-left" reverseOrder={false} />
      <SideBar list={[]} currentDestination="Project" />
      <div className="md:p-4 sm:ml-64">
        <div className="p-2 md:mt-3">
          <h4>Project </h4>
          <ProjectTable projects={projects} />
        </div>
      </div>
    </div>
  );
}
export default QualityAssuranceProject;
