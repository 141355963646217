const highContrastingColors = ['rgba(0,255,81,1)', 'rgba(255,219,0,1)', 'rgba(255,0,0,1)', 'rgba(0,4,255,1)', 'rgba(227,0,255,1)', 'rgba(255,215,0,1)',
	'rgba(255,165,0,1)', 'rgba(255,255,0)', 'rgba(220,20,60,1)', 'rgba(189,183,107,1)', 'rgba(0,255,127,1)', 'rgba(255,99,71,1)', 'rgba(238,232,170,1)', 'rgba(154,205,50,1)', 'rgba(250,128,114,1)'];
var randomColor = require('randomcolor');
//  const highContrastingColors = randomColor({
// 	hue: 'green',
// 	luminosity: 'light',
// 	format: 'rgba' // e.g. 'rgba(9, 1, 107, 0.6482447960879654)'
//  });
console.log(highContrastingColors)
export {
	highContrastingColors,
};
