import { LoadingButton } from "@mui/lab";
import { CircularProgress, Dialog, TextField } from "@mui/material";
import React, { useState } from "react";
import auth from "../api/auth";
import sorterLogo from "../assets/images/sorteraLogo.png";
import OtpView from "../Components/otpView";

function LoginPage() {
  const [error, setError] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [otpExpiredDate, setOtpExpiredDate] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [loading, setLoading] = useState(false);

  const singIn = () => {
    const data = {
      email: email,
      password: password,
    };
    setError([]);
    setLoading(true);
    setDisabledBtn(true);

    auth
      .login(data)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          localStorage.setItem("otp_token", response.data.token);
          setOpenDialog(true);
        } else if (response.status === 201) {
          localStorage.setItem("reset_token", response.data.token);

          window.location.href = "/set-password";
        }

        setOtpExpiredDate(response.data.expired_at);
        setEmail("");
        setPassword("");
        setDisabledBtn(true);
        setError([]);
        setLoading(false);
      })
      .catch((error) => {
        if(error.code === "ERR_BAD_REQUEST"){
          if(error.response.status === 422){
            setError(error.response.data.validationRequestResult.errors);
            console.log(error.response.data.validationRequestResult.errors.index)
          } else if(error.response.status === 400) {
            setError([{ msg:error.response.data.message,path:"password"}]) ;
          } else {
            setError("Error please try again");
          }
        }
         setLoading(false);
      });
  };
  const handelOpenDialog = () => {
    setOpenDialog(false);
  };
  return (
    <div>
      <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src={sorterLogo}
            alt="Sortera Logo"
            style={{ width: "250px", height: "250px" }}
          />

          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            error={error.some(error=>error.path === "email")}
            helperText={error.some(error=>error.path === "email") && error.map((error)=>{if(error.path === "email"){return error.msg}})}
            style={{ width: "100%", margin: "10px" }}
            value={email}

            onChange={(e) => {
              if (e.target.value === "" || password === "") {
                setDisabledBtn(true);
              } else {
                setDisabledBtn(false);
              }
              setEmail(e.target.value);
            }}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            style={{ width: "100%", margin: "10px" }}
            error={error.some(error=>error.path === "password")}
            value={password}
            helperText={error.some(error=>error.path === "password") && error.map((error)=>{if(error.path === "password"){return error.msg}})}
            onChange={(e) => {
              if (e.target.value === "" || email === "") {
                setDisabledBtn(true);
              } else {
                setDisabledBtn(false);
              }
              setPassword(e.target.value);
            }}
          />

          <div className="flex items-center justify-between">
            <div className="text-sm items-end">
              <a href="/" style={{ color: "#212e79" }}>
                Forgot password?
              </a>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md  m-[10px] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-[#212e79] disabled:bg-[#e8e4e4] "
              // style={{ backgroundColor: "#212e79" }}
              disabled={disabledBtn}
              onClick={() => {
                singIn();
              }}
            >
              {loading ? (
                <CircularProgress style={{ width: "30px", height: "30px" }} />
              ) : (
                "Sign in"
              )}
            </button>
          </div>
          <Dialog open={openDialog}>
            <OtpView
              email={email}
              otpExpiredDate={otpExpiredDate}
              handelOpenDialog={handelOpenDialog}
            />
          </Dialog>
          {/* </form> */}
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
