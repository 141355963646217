
import axios from "axios";
import { HTTP } from "./api";

export default {

    createTicket(data) {
        return HTTP.post("support/", data);
    },

    getMyTicket() {
        return HTTP.get("support/my-tickets");
    },

    getTicketById(id) {
        return HTTP.get(`support/get-ticket/${id}`);
    },
    getAllTickets() {
        return HTTP.get(`support/`);
    },

    solveTicket(ticketId) { 
       return HTTP.post(`support/solve-ticket/${ticketId}`);
    }


}