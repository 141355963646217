import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import SideBar from "../../Components/SideBar";
import { adminFeatures } from "../../Data/usersFeatures";
import admin from "../../api/admin";
import Message from "../../Components/PageMessage";
import UsersTable from "../../Components/UserTable";
import toast, { Toaster } from "react-hot-toast";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

function Annotator() {
  const [openDialog, setOpenDialog] = useState(false);
  const [annotators, setAnnotators] = useState([]);
  const [annotatorName, setAnnotatorName] = useState("");
  const [annotatorEmail, setAnnotatorEmail] = useState("");
  const [annotatorPhoneNumber, setAnnotatorPhoneNumber] = useState("");
  const [currentUser, setCurrentUser] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [annotatorNumbersError, setAnnotatorNumbersError] = useState("");
  const[isLoadingData,setIsLoadingData] = useState(true);



  const handleClose = () => {

    setError([]);
    setAnnotatorNumbersError("");
    setAnnotatorName("");
    setAnnotatorEmail("");
    setAnnotatorPhoneNumber("");
    setOpenDialog(false);
    setDisabledBtn(true);
  }
  const createNewAnnotator = async () => {
    setLoading(true);
    setError([]);
    const data = {
      name: annotatorName,
      email: annotatorEmail,
      phone_number: annotatorPhoneNumber,
      password: "123456",
      type: "3",
      owner_id: currentUser.id,
    };
    await admin
      .createNewUser(data)
      .then((response) => {
        toast.success("Annotator created successfully", {
          style: { padding: "15px" },
        });
        window.location.reload();
        setDisabledBtn(true);

      })
      .catch((error) => {
        console.log(error);
        if (error.code === "ERR_BAD_REQUEST") {
          if (error.response.status === 422) {
            setError(error.response.data.validationRequestResult.errors);

          } else if (error.response.status === 400) {

            if (error.response.data.code === 40004) {
              setError([{ msg: error.response.data.message, path: "email" }]);
            } else if (error.response.data.code === 40003) {
              setAnnotatorNumbersError(error.response.data.message);
            } else {
              toast.error("Error, Please Try Again!! ", {
                style: { padding: "15px" },
              });
            }
          }

          setLoading(false);

        } else {
          toast.error("Error, Please Try Again!! ", {
            style: { padding: "15px" },
          });
        }

      });
  };

  useEffect(() => {
    const getAnnotatorsData = async () => {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      const owner_id = currentUser.id;

      setCurrentUser(currentUser);
      await admin
        .getMyAnnotators(owner_id)
        .then((response) => {
          setIsLoadingData(false)
          setAnnotators(response.data.annotators);

        })
        .catch((error) => {
          if (error.code === "ERR_NETWORK") {

            toast.error("Error! please try again", {
              style: { padding: "15px" },
            });

          } else if (error.response.status === 401) {
            localStorage.clear();
            window.location = "/login"
          } else {
            toast.error("Error! please try again", {
              style: { padding: "15px" },
            });
          }
        });
    };
    getAnnotatorsData();
  }, []);
  return (
    <div className="App" style={{ minHeight: "100vh" }}>
      <SideBar currentDestination="Annotator" />
      <Toaster position="bottom-left" reverseOrder={false} />
      <div className="md:p-4 sm:ml-64">
        <div className="p-2 md:mt-3">
          <h4>Annotator </h4>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <button
                onClick={() => setOpenDialog(true)}
                type="button"
                className=" text-white bg-[#212e79]  focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center  float-right m-2"
              >
                New Annotator
              </button>
            </Grid>
          </Grid>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 m-6 min-md:grid-cols-1 ">
            {/* {annotators.map((annotator, index) => (
              <ProjectCard
                key={index}
                title={annotator.name}
                description={annotator.email}
                createdAt="22 December 2023"
                type={1}
              />
            ))} */}
          </div>

          {isLoadingData ? (
             <Box sx={{ display: 'flex', justifyContent: "center",height:"80vh" ,width:"100%" ,alignItems:"center"}}>
             <CircularProgress style={{ color: "#212e79" }} size="90px" />
           </Box>
          ) : (
            <>
              {annotators.length <= 0 ? (
            <>
              <Message type={2} />
            </>
          ) : (
            <UsersTable users={annotators} />
          )}
            
            </>
          )}


        
        </div>
      </div>

      {/* Create New Annotator  */}
      <Dialog open={openDialog}>
        <div className="p-4 border-b rounded-t">
          <p className="text-4xl font-thin text-gray-900 ">Add New Annotator</p>
          <p className="text-justify text-gray-500 ">
            To Add annotator to Sortera, please fill in all the fields. We will
            send the login information to the annotator.
          </p>
        </div>

        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            error={error.some(error => error.path === "name")}
            helperText={error.some(error => error.path === "name") && error.map((error) => { if (error.path === "name") { return error.msg } })}
            type="text"
            fullWidth
            variant="outlined"
            value={annotatorName}
            onChange={(e) => {
              if (e.target.value === "" || annotatorEmail === "" || annotatorPhoneNumber === "") {
                setDisabledBtn(true);
              } else {
                setDisabledBtn(false);
              }
              setAnnotatorName(e.target.value)
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            error={error.some(error => error.path === "email")}
            helperText={error.some(error => error.path === "email") && error.map((error) => { if (error.path === "email") { return error.msg } })}
            type="email"
            onChange={(e) => {
              if (e.target.value === "" || annotatorName === "" || annotatorPhoneNumber === "") {
                setDisabledBtn(true);
              } else {
                setDisabledBtn(false);
              }
              setAnnotatorEmail(e.target.value)
            }}
            fullWidth
            variant="outlined"
            value={annotatorEmail}

          />

          <TextField
            autoFocus
            margin="dense"
            id="phoneNumber"
            label="Phone Number"
            type="text"
            error={error.some(error => error.path === "phone_number")}
            helperText={error.some(error => error.path === "phone_number") && error.map((error) => { if (error.path === "phone_number") { return error.msg } })}
            fullWidth
            variant="outlined"
            value={annotatorPhoneNumber}

            onChange={(e) => {
              if (e.target.value === "" || annotatorEmail === "" || annotatorName === "") {
                setDisabledBtn(true);
              } else {
                setDisabledBtn(false);
              }
              setAnnotatorPhoneNumber(e.target.value)
            }}
          />

          {annotatorNumbersError != "" && (
            <div className="p-2">
              <p className="flex items-center text-sm font-medium text-[#BC1C21]">
                <span className="pr-3">
                  <ReportGmailerrorredIcon />
                </span>
                {annotatorNumbersError}
              </p>
            </div>
          )}

        </DialogContent>
        <DialogActions>
          <Button
            style={{ margin: "10px" }}
            onClick={() => handleClose()}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>

          <LoadingButton
            style={{ margin: "10px", borderRadius: "6px", padding: '6px', backgroundColor: disabledBtn ? "#e0e0e0" : "#212e79", color: disabledBtn ? "#a6a6a6" : "white" }}
            className="flex  justify-center rounded-md  m-[10px] px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm  "
            size="medium"
            loading={loading}
            variant="contained"
            onClick={() => createNewAnnotator()}
            disabled={disabledBtn}

          >
            Create
          </LoadingButton>

        </DialogActions>
      </Dialog>
    </div>
  );
}
export default Annotator;
