export const projectsData = [
  {
    id: 0,
    title: "CMD Project",
    description: "Example",
    createdAt: "23 Apr 2023",
  },
  {
    id: 1,
    title: "CMN Project",
    description: "Example",
    createdAt: "12 May 2022",
  },
  {
    id: 2,
    title: "NNS",
    description: "Description",
    createdAt: "10 Oct 2022",
  },
  {
    id: 3,
    title: "Project Title",
    description: "project description",
    createdAt: "10 Oct 2022",
  },
  {
    id: 4,
    title: "ASW Project",
    description: "example project description",
    createdAt: "10 Apr 2021",
  },
];
export const stepsData = [
  {
    title: "Project Information",
    description: "Please Fill in project information",
  },
  { title: "Project Data", description: "Please Import the Data" },
  {
    title: "Classification",
    description: "Please Create Classification ",
  },
  {
    title: "Project Annotators",
    description: "Please Select the Annotator ",
  },

  {
    title: "Create Project",
    description: "When you select Finish Button the Project will be created",
  },
];

export const annotationsData = [
  {
    id: 0,
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/401_Gridlock.jpg/1200px-401_Gridlock.jpg",
    title: "Car Annotation",
    projectName: "CMD",
    direction: "CMD/123",
    description:
      "Annotate each text according to this markdown Classification.",
    taskType: "Image Annotation",
  },
  {
    id: 1,
    image: "https://ychef.files.bbci.co.uk/1280x720/p09y4h9x.jpg",
    title: "Human Annotation",
    projectName: "CMP",
    direction: "CMP/1266",
    description:
      "Annotate each text according to this markdown Classification.",
    taskType: "Image Annotation",
  },
  {
    id: 2,
    image: "https://cdn.hswstatic.com/gif/tallest-building-1.jpg",
    title: "Building Annotation",
    projectName: "CMO",
    direction: "/CMO/9099",
    description:
      "Annotate each text according to this markdown Classification.",
    taskType: "Image Annotation",
  },
  {
    id: 3,
    image:
      "https://www.austinmonitor.com/wp-content/uploads/2016/02/6293502027_4b97a5113c_b.jpg",
    title: "Traffic Light",
    projectName: "CMO",
    direction: "/CMO/1234",
    description:
      "Annotate each text according to this markdown Classification.",
    taskType: "Text Annotation",
  },
  {
    id: 4,
    image:
      "https://www.vmcdn.ca/f/files/glaciermedia/import/lmp-all/1476025-suncor-traffic-light.jpg;w=936;h=702;mode=crop",
    title: "Traffic Light",
    projectName: "CMI",
    direction: "/CMI/12355",
    description:
      "Annotate each text according to this markdown Classification.",
    taskType: "Text Annotation",
  },
];
export const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export const text =
  "When Sebastian Thrun started working on self-driving cars at Google in 2007, few people outside of the company took him seriously. “I can tell you very senior CEOs of major American car companies would shake my hand and turn away because I wasn’t worth talking to,” said Thrun, now the co-founder and CEO of online higher education startup Udacity, in an interview with Recode earlier this week. A little less than a decade later, dozens of self-driving startups have cropped up while automakers around the world clamor, wallet in hand, to secure their place in the fast-moving world of fully automated transportation.";
