import axios from "axios";
import { HTTP } from "./api";

export default {
  getMyProjects(id) {
    return HTTP.get( `admin/projects/${id}`);
  },

  getProjectTasks(id) {
    return HTTP.get(`task/project-tasks/${id}`);
  },

  createAnnotation(data) {
    return HTTP.post(
      "task/make-annotation",
      data
    );
  },
  getTask(id) {
    return HTTP.get(`task/${id}`);
  },

  getUserTasks(id) {
    return HTTP.get(`task/user-tasks/${id}`);
  },
  resetPassword(data) {
    return HTTP.post("/user/reset-password", data);
  },

  getAnnotatorProjectTasks(data){
    return HTTP.get(`task/project-tasks/${data.project_id}/annotator/${data.annotator_id}`);
  }
};
