import React, { useEffect, useState } from "react";
import SideBar from "../../Components/SideBar";
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress, Grid } from "@mui/material";
import TasksTable from "../../Components/TasksTable";
import admin from "../../api/admin";
import toast, { Toaster } from "react-hot-toast";

function UserTasks() {

    const location = useLocation();
    const [tasks, setTasks] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(true);

    useEffect(() => {

        const getTasks = async () => {

            if (location.state.type == 2) {
                await admin.getMyQualityAssuranceApprovedTasks(location.state.user_id)
                    .then((response) => {
                        setTasks(response.data.tasks);
                        setIsLoadingData(false);
                    })
                    .catch((error) => {

                        if (error.code === "ERR_NETWORK") {

                            toast.error("Error! please try again", {
                                style: { padding: "15px" },
                            });

                        } else if (error.response.status === 401) {
                            localStorage.clear();
                            window.location = "/login"
                        } else {
                            toast.error("Error! please try again", {
                                style: { padding: "15px" },
                            });
                        }
                    });
            } else if (location.state.type == 3) {
                await admin.getAnnotatorFinishedTasks(location.state.user_id)
                    .then((response) => {
                        setTasks(response.data.tasks);
                        setIsLoadingData(false);
                    })
                    .catch((error) => {
                        if (error.code === "ERR_NETWORK") {

                            toast.error("Error! please try again", {
                                style: { padding: "15px" },
                            });

                        } else if (error.response.status === 401) {
                            localStorage.clear();
                            window.location = "/login"
                        } else {
                            toast.error("Error! please try again", {
                                style: { padding: "15px" },
                            });
                        }
                    });
            }
        }

        if (location.state === null) {
            window.location.href = "/";
        }
        getTasks();
    }, [])

    return (
        <div className="App" style={{ minHeight: "100vh" }}>
            <SideBar currentDestination={location.state?.type == 2 ? "Quality Assurance" : "Annotator"} />
            <Toaster position="bottom-left" reverseOrder={false} />
            <div className="md:p-4 sm:ml-64">
                <div className="p-2 md:mt-3">

                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <div className="flex  items-center drop-shadow-md m-1">
                            <span className=" text-gray-500 text-lg">Name:</span>
                            <span className='p-2'>{location.state?.user?.name}</span>
                        </div>


                        <div className="flex  items-center drop-shadow-md m-1">
                            <span className=" text-gray-500 text-lg">Email:</span>
                            <span className='p-2'>{location.state?.user?.email}</span>
                        </div>

                        <div className="flex  items-center drop-shadow-md m-1">
                            <span className=" text-gray-500 text-lg">Phone Number:</span>
                            <span className='p-2'>{location.state?.user?.phone_number}</span>
                        </div>
                    </Grid>

                    <div className="my-3">

                        {isLoadingData ? (
                            <Box sx={{ display: 'flex', justifyContent: "center", height: "80vh", width: "100%", alignItems: "center" }}>
                                <CircularProgress style={{ color: "#212e79" }} size="90px" />
                            </Box>
                        ) : (
                            <>
                                <TasksTable data={tasks} type={1} />
                            </>
                        )}

                    </div>



                </div>
            </div>
        </div>
    )
}

export default UserTasks;