import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from '@mui/icons-material/Home';

export const sorteraAdmin=[

  {
    title: "Home",
    destination: "/sortera-admin",
    icon: <HomeIcon />,
  },
];

export const adminFeatures = [
  // { title: "Dashboard", destination: "/admin", icon: <DashboardIcon /> },
  {
    title: "Project",
    destination: "/admin",
    icon: <AccountTreeIcon />,
  },
  {
    title: "Quality Assurance",
    destination: "/admin/quality-assurance",
    icon: <ManageAccountsIcon />,
  },
  { title: "Task", destination: "/admin/task", icon: <SplitscreenIcon /> },

  {
    title: "Annotator",
    destination: "/admin/annotator",
    icon: <PersonIcon />,
  },

];

export const annotatorFeatures = [
  // {
  //   id: 0,
  //   title: "Dashboard",
  //   destination: "/annotator/",
  //   icon: <DashboardIcon />,
  // },
  {
    id: 1,
    title: "My Tasks",
    destination: "/annotator/",
    icon: <SplitscreenIcon />,
  },
  {
    id: 2,
    title: "Project",
    destination: "/annotator/project",
    icon: <AccountTreeIcon />,
  },

];
export const qualityAssuranceFeatures = [
  // {
  //   id: 0,
  //   title: "Dashboard",
  //   destination: "/quality-assurance/",
  //   icon: <DashboardIcon />,
  // },
  {
    id: 1,
    title: "My Tasks",
    destination: "/quality-assurance/",
    icon: <SplitscreenIcon />,
  },
  {
    id: 2,
    title: "Project",
    destination: "/quality-assurance/project",
    icon: <AccountTreeIcon />,
  },

];
