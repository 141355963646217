import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import SideBar from "../../Components/SideBar";
import { adminFeatures } from "../../Data/usersFeatures";
import user from "../../api/user";
import toast, { Toaster } from "react-hot-toast";
import CreateProject from "../../Components/createProject";
import Message from "../../Components/PageMessage";
import ProjectTable from "../../Components/ProjectTable";
import { useSelector } from "react-redux";

function Project() {
  // data

  const [showAddNewTask, setShowAddNewTask] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [projectsData, setProjectData] = useState([]);
  const [isLoadingData,setIsLoadingData] = useState(true);
  const projects = useSelector((state) => state.project.project);
  const userInfo = useSelector((state) => state.user.user);

  // functions
  const onClickAddNewTaskBtn = () => {
    setActiveStep(0);
    setShowAddNewTask(!showAddNewTask);
  };

  useEffect(() => {

    const getProjects = async () => {
      let currentUser = JSON.parse(localStorage.getItem("user"));

      const data = currentUser.id;
      await user
        .getMyProjects(data)
        .then((response) => {
          setProjectData(response.data.projects);
          setIsLoadingData(false);
        })
        .catch((error) => {
          if (error.code === "ERR_NETWORK") {

            toast.error("Error! please try again", {
              style: { padding: "15px" },
            });

          } else if (error.response.status === 401) {
            localStorage.clear();
            window.location = "/login"
          } else {
            toast.error("Error! please try again", {
              style: { padding: "15px" },
            });
          }
        });
    };

    getProjects();
  }, []);

  return (
    <div className="App" style={{ minHeight: "100vh" }}>
      <div>
        <Toaster position="bottom-left" reverseOrder={false} />
      </div>

      <SideBar currentDestination="Project" list={adminFeatures} />

      <div className="md:p-4 sm:ml-64">
        <div className="p-2 md:mt-3">
          <h4>Welcome, {JSON.parse(localStorage.getItem("user"))?.name} </h4>
          <h4>Project </h4>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {/* Top the page */}
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <button
                onClick={onClickAddNewTaskBtn}
                type="button"
                className={`text-white  focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center  float-right m-2 ${showAddNewTask
                  ? "bg-red-700 hover:bg-red-800 "
                  : "bg-[#212e79] hover:bg-[#212e79]"
                  }`}
              >
                {showAddNewTask ? "Cancel" : "New Project"}
              </button>
            </Grid>
            {/* the content of the page */}
            {/* case one: the projects table */}


            {isLoadingData ? (
               <Box sx={{ display: 'flex', justifyContent: "center", height: "80vh", width: "100%", alignItems: "center" }}>
               <CircularProgress style={{ color: "#212e79" }} size="90px" />
             </Box>

            ):(
              <>
                 {!showAddNewTask && (
              <div className="w-full">
                {projectsData.length > 0 && (
                  <ProjectTable projects={projectsData} />
                )}
              </div>
            )}
            {/* case Two: add a new project form */}
            {showAddNewTask && (
              <CreateProject
                setShowAddNewTask={setShowAddNewTask}
                setActiveStep={setActiveStep}
                activeStep={activeStep}
                toast={toast}
              />
            )}
            {!showAddNewTask && projectsData.length <= 0 && (
              <Message type={0} />
            )}
              </>
            )}

         
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Project;
